/* Dots */
.slick-dots
{
    position: absolute;
    z-index: 2; /* ie9 */
    bottom: 0;

    display: block;

    width: 100%;
    padding: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 3px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    opacity:.6;
    display: block;
    font-size: 0;
    line-height: 0;
    border-radius:50%;
    width: 2px;
    height: 2px;
    padding:3px;
    cursor: pointer;
    color: transparent;
    border: 2px solid #fff;
    box-shadow:0 0 2px rgba(0,0,0,.5);
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
    opacity:1;
}
.slick-dots li button:before {
  background-color:transparent;
}


.slick-dots li.slick-active button
{
    background-color:#fff;
    opacity:1;
}
