// Core variables and mixins
@import '../../bower_components/bootstrap/less/variables.less';
@import '../../bower_components/bootstrap/less/mixins.less';

// Reset
//@import "../../bower_components/bootstrap/less/normalize.less";
@import '../../bower_components/bootstrap/less/print.less';

// Core CSS
//@import "../../bower_components/bootstrap/less/scaffolding.less";
@import '../../bower_components/bootstrap/less/type.less';
//@import "../../bower_components/bootstrap/less/code.less";
@import '../../bower_components/bootstrap/less/grid.less';
@import '../../bower_components/bootstrap/less/tables.less';
@import '../../bower_components/bootstrap/less/forms.less';
@import '../../bower_components/bootstrap/less/buttons.less';

// Components
@import '../../bower_components/bootstrap/less/component-animations.less';
//@import "../../bower_components/bootstrap/less/glyphicons.less";
@import '../../bower_components/bootstrap/less/dropdowns.less';
@import '../../bower_components/bootstrap/less/button-groups.less';
@import '../../bower_components/bootstrap/less/input-groups.less';
@import '../../bower_components/bootstrap/less/navs.less';
@import '../../bower_components/bootstrap/less/navbar.less';
@import '../../bower_components/bootstrap/less/breadcrumbs.less';
@import '../../bower_components/bootstrap/less/pagination.less';
//@import "../../bower_components/bootstrap/less/pager.less";
@import '../../bower_components/bootstrap/less/labels.less';
@import '../../bower_components/bootstrap/less/badges.less';
//@import "../../bower_components/bootstrap/less/jumbotron.less";
@import '../../bower_components/bootstrap/less/thumbnails.less';
@import '../../bower_components/bootstrap/less/alerts.less';
@import '../../bower_components/bootstrap/less/progress-bars.less';
//@import "../../bower_components/bootstrap/less/media.less";
@import '../../bower_components/bootstrap/less/list-group.less';
@import '../../bower_components/bootstrap/less/panels.less';
//@import "../../bower_components/bootstrap/less/wells.less";
@import '../../bower_components/bootstrap/less/close.less';
@import '../../bower_components/bootstrap/less/responsive-embed.less';

// Components w/ JavaScript
//@import "../../bower_components/bootstrap/less/modals.less";
@import '../../bower_components/bootstrap/less/tooltip.less';
@import '../../bower_components/bootstrap/less/popovers.less';
//@import "../../bower_components/bootstrap/less/carousel.less";

// Utility classes
@import '../../bower_components/bootstrap/less/utilities.less';
@import '../../bower_components/bootstrap/less/responsive-utilities.less';

@import (less) '../../bower_components/magnific-popup/dist/magnific-popup.css';
@import (less) '../../bower_components/photoswipe/dist/photoswipe.css';
@import '../lib/photoswipe/photoswipe-skin.less';
@import 'reboot';
@import 'print';
@import 'slick';
@import 'slick-theme';
@import 'icons';
@import 'mixins';
@import 'utils';
@import 'forms';
@import 'buttons';
@import 'select';
@import 'dropdowns';
@import 'cards';
@import 'alerts';
@import 'list-group';
@import 'media';
@import 'navbar';
@import 'navbar-tablet';
@import 'navbar-mobile';
@import 'utilities/spacing';
@import 'utilities/display';
@import 'variables';

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
html {
  scroll-behavior: smooth;
}
body {
  padding-bottom: 3rem;
}

//https://github.com/dimsemenov/Magnific-Popup/issues/782
body {
  -webkit-overflow-scrolling: touch;
}
body.mfp-active {
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
  width: 100%;
  position: fixed;
  overflow: auto;
}
body .mfp-wrap {
  position: fixed;
  overflow: auto;
  top: 0 !important;
}

body.narrow {
  .main-body {
    .container-fluid {
      max-width: 700px;
    }
  }
}

:target {
  background-color: #fffbe1;
}

small,
.small {
  font-size: 0.88em;
}

strong {
  font-weight: 600; // bold fonts are ugly on windows
}

ul,
ol {
  padding-left: 2rem;
}

.text-nowrap,
.nowrap {
  white-space: nowrap;
}
.btn-wrap {
  white-space: normal;
}

.text-underlined {
  text-decoration: underline !important;
}

p:empty {
  margin-bottom: 0;
}

[hidden] {
  display: none !important;
}

.bg-em {
  .bg-variant(#eee);
}

.panel {
  .panel-body {
    > :last-child {
      margin-bottom: 0;
    }
  }
  .panel-title {
    font-family: @font-family-serif, serif;
    font-weight: bold;
    font-size: 1.2em;
  }
  > .result-grid {
    margin: 0;
    border-top: 0;
  }
  > .alert {
    margin-bottom: 0;
  }
}

.panel-danger {
  .list-group-item {
    border: 1px solid @panel-danger-border;
  }
}
.list-group-item-title {
  font-size: 1.15em;
  font-weight: bold;
  line-height: 1.2;
}

.table-brand {
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 1px solid @card-border-color;
  }
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border-top-color: @body-bg-darker;
      }
    }
  }
  tbody {
    > tr {
      > th {
        border-right: 1px solid darken(@brand-bg-color, 6%);
      }
    }
  }
  th {
    font-weight: 600;
    background-color: @body-bg-darker;
  }
}

.table {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        > ul:last-child,
        > ol:last-child,
        > p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

* {
  .box-sizing(border-box);
  -webkit-font-smoothing: antialiased;
}
img {
  -ms-interpolation-mode: bicubic;
}

.main-body {
  @media (max-width: @grid-float-breakpoint) {
    margin-top: @navbar-bottom-margin-mobile;
  }
  margin-top: @navbar-bottom-margin;
  .clearfix;
}

.banner-page .main-body,
.product-page .main-body,
.search-page .main-body,
.index-page .main-body {
  margin-top: 0;
}

.classification-grid-wrapper {
  .card-header,
  .card-footer {
    position: relative;
    text-align: center;
    .card-title {
      background-color: @body-bg-darker;
      position: relative;
      z-index: 1;
      display: inline-block;
      padding: 0.5rem 1.5rem;
      color: @text-color;
      > .btn {
        color: @text-color;
      }
    }
    &:after {
      position: absolute;
      content: '';
      display: block;
      left: 20px;
      right: 20px;
      top: 50%;
      bottom: 0;
      border-top: 2px solid fadeout(desaturate(@brand-color, 30%), 90%);
      z-index: 0;
    }
  }
  .card-footer {
    border-top: 0;
    padding: 0;
    margin-top: -2rem;
    @media (min-width: 490px) {
      margin-top: -2.25rem;
    }
    margin-bottom: 1rem;
    .card-title {
      margin-top: 1rem;
    }
  }

  @media (max-width: @screen-xs) {
    .card-header {
      h1.card-title {
        max-width: 250px;
      }
    }
  }
}

.banner-content {
  position: relative;
  z-index: 2;
  background-color: opacity(@body-bg, 0.2);
}

.container-fluid {
  text-align: left;
  margin: 0 auto;
  max-width: 1400px;
  min-width: (280px - @grid-gutter-width);
  padding: 0;
  padding-left: @grid-gutter-width / 2;
  padding-right: @grid-gutter-width / 2;
}

.container-fluid-full-width {
  .container-fluid;
  max-width: none;
}

main {
  display: block;
}

.table > tbody > tr.noborder > td {
  border-top: none;
}

a.list-group-item {
  text-decoration: none;
  &:hover,
  &:active {
    color: @text-color;
  }
}

// Spacing
p,
.p {
  margin-bottom: @hspace;
}

.padded {
  padding: @vspace @hspace;
}

a {
  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
    color: (@link-color + 45%);
  }
}

// forms

label {
  margin-bottom: 0.25rem;
  font-weight: normal;
}

.form-control {
  .box-shadow(inset 0 1px 1px @brand-bg-color);
}

fieldset,
.form-group,
textarea.form-control,
.checkout-fieldset {
  max-width: @form-group-max-width;
}
.form-group .bootstrap-select.btn-group {
  display: block;
}
.variant-selector {
  .bootstrap-select {
    width: 100% !important;
  }
}

.address-selector,
.address-form {
  .form-control {
    max-width: 100%;
  }
}
.address-selector {
  .address-fieldset {
    margin-top: 1.5rem;
  }
}

.full-width > .form-control {
  max-width: 100%;
}

.product-main {
  display: table;
  width: 100%;

  .product-page-title {
    display: table-header-group;
  }

  .add-to-cart-column-wrapper {
    display: table-row-group;
    width: 100%;

    .add-to-cart-column {
      > * {
        max-width: 400px;
        min-width: 260px;
        @media (max-width: @screen-sm) {
          margin-left: auto;
          margin-right: auto;
        }
      }
      > .add-to-cart-box {
        margin-bottom: 1rem;
      }
    }
  }

  @media (min-width: @screen-lg) {
    .product-page-title {
      display: block;
    }
    .add-to-cart-column-wrapper {
      display: block;
      float: right;
      width: 35%;
      margin-left: 2rem;
      margin-bottom: @line-height-computed;
    }
  }

  .product-page-title,
  > .description-info,
  > .description-warning,
  > .description-extra,
  > .description-video,
  > .card,
  > .description {
    max-width: 530px;
  }
}

.description-video {
  .card-body {
    padding: 0;
  }
}

a.color-inherit {
  color: inherit;
}

// Headers
h1,
.h1 {
  font: bold 30px/1.25 @font-family-serif;
  margin-bottom: 1.25rem;
  letter-spacing: -0.5px;
  .btn {
    font-family: @font-family-base;
  }
  > small {
    font-size: @font-size-base;
    font-family: @font-family-base;
    letter-spacing: 0;
    > a {
      color: @link-color;
    }
  }
  @media (max-width: @screen-sm) {
    font-size: 26px;
    letter-spacing: -0.3px;
  }
}

h2,
.h2 {
  font-family: @font-family-serif;
  font-weight: bold;
  // PT Serif with a font-size under 21px is ugly on Windows
  font-size: 22px;
  letter-spacing: -0.3px;
  margin-bottom: 0.9rem;
  color: @headings-color;
  @media (max-width: @screen-sm) {
    font-size: 19px;
  }
}

h3,
.h3 {
  margin-top: 0;
  font-size: 1.05rem;
  font-weight: 700;
  margin-bottom: 0.7rem;
  color: @headings-color;
  @media (max-width: @screen-sm) {
    font-size: 1rem;
  }
}

h4,
.h4 {
  font-size: 14px;
  font-weight: 700;
  line-height: @line-height-base;
  margin-bottom: 0.5rem;
  color: @headings-color;
}

h1 a,
.h1 a,
h2 a,
.h2 a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#specs {
  .table {
    tr:first-child td,
    tr:first-child th {
      border-top: none;
    }
    p,
    ul {
      margin: 0;
    }
    ul {
      padding-left: 20px;
    }
  }
}

hr {
  height: 0;
  line-height: 1.4em;
  border: none;
  border-top: 1px solid darken(@body-bg, 12%);
  border-bottom: 1px solid lighten(@body-bg, 12%);
  margin: @vspace 0;
}

textarea.placeholder,
input.placeholder {
  color: #bbb;
}

h2 {
  .btn {
    font-family: arial, sans-sherif;
    letter-spacing: 0;
  }
}

/* result list */
.result-item {
  margin: 2.5rem 0;
  max-width: 50em;
  &:extend(.clearfix);
  overflow: hidden;
  .media-left,
  .media-right {
    display: flex;
    align-items: center;
    width: 125px;
    min-width: 125px;
    @media (max-width: 600px) {
      width: 100px;
      min-width: 100px;
    }
    @media (max-width: 400px) {
      width: 70px;
      min-width: 70px;
    }
    img {
      border-radius: @border-radius-base;
    }
  }

  h3 {
    font-weight: normal;
    font-size: 1rem;
  }

  h3 > a {
    text-decoration: none;
  }

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  .rating-count {
    font-size: 0.85em;
    vertical-align: top;
  }

  .media-heading {
    line-height: 1.3;
    margin-bottom: 3px;
  }

  h3 {
    margin: 0;
    font-family: @font-family-sans-serif;
    letter-spacing: 0;
    line-height: 1.35;
    .result-brand {
      font-size: 0.8rem;
      color: @text-muted;
      white-space: nowrap;
    }
  }

  .media-left,
  .media-right,
  .pull-left,
  .pull-right {
    text-align: center;
    max-width: 200px;

    img {
      max-width: 100%;
      margin: 4px auto;
      height: auto;
      max-height: 150px;
    }
  }

  .result-text {
    -ms-word-break: break-all;
    word-break: break-all;

    // Non standard for webkit
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
}

.result-item-small {
  max-width: 350px;
  .media-left,
  .media-right {
    width: 100px;
    min-width: 100px;

    img {
      width: auto;
      max-height: 100px;
      max-width: 100%;
    }
  }
}

.result-topic,
.result-topic-small,
.result-article {
  .pull-right,
  .pull-left {
    img {
      width: 70px;
    }
  }
}

.result-item {
  .labels-and-rating {
    display: flex;
    align-items: center;
  }
  .rating {
    float: left;
    margin-right: 0.5em;
  }
  .product-variants {
    .text-muted;
  }
}

.result-images {
  margin-top: 5px;
}

.review-item {
  .media-left {
    text-align: center;
    min-width: 160px;
  }
}

.product-variants {
  td {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 10px;
    white-space: nowrap;
  }
  .result-price {
    overflow: hidden;
    white-space: nowrap;
    .price;
  }
}

.labels {
  overflow: hidden;
  margin: 0.25rem 0;
}
.label {
  float: left;
  padding: 0.35em 0.6em;
  margin-right: 5px;
}

.label-ribbon {
  + .label-ribbon {
    margin-top: 3px;
  }
}

.label-ribbon {
  display: block;
  float: left;
  clear: left;
  position: relative;
  padding: 5px 0px 5px 10px;
  margin-right: 22px;
  border-radius: 2px 0 0 2px;
  font-family: @font-family-base;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0;
  font-weight: 600;
  font-size: 0.7rem;

  &:before {
    content: '';
    position: absolute;
    display: block;
    right: -22px;
    top: 0;
    // add .1px to prevent chrome on android from changing the value to 10.6667px
    border: 11.1px solid transparent;
  }
  &.label-default:before {
    border-color: @label-default-bg;
    border-right-color: transparent;
  }
  &.label-new:before {
    border-color: @label-success-bg;
    border-right-color: transparent;
  }
  &.label-liquidation:before {
    border-color: @label-warning-bg;
    border-right-color: transparent;
  }
  &.label-pro-use:before {
    border-color: @label-warning-bg;
    border-right-color: transparent;
  }
  &.label-discount:before {
    border-color: @label-danger-bg;
    border-right-color: transparent;
  }
  &.label-recommended:before {
    border-color: @label-info-bg;
    border-right-color: transparent;
  }
}

.price {
  color: @text-color;
  font-weight: 600;
  white-space: nowrap;
  font-size: 110%;
}

.banner-page {
  .banner-wrapper {
    position: relative;

    background-color: @body-bg;
    z-index: 0;
    @media (min-width: 1400px) {
      border-bottom-left-radius: @border-radius-base;
      border-bottom-right-radius: @border-radius-base;
    }
  }
  .banner {
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-bottom: 0.5rem;
    @media (min-width: 1400px) {
      border-bottom-left-radius: @border-radius-base;
      border-bottom-right-radius: @border-radius-base;
    }
  }
  .banner-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(to bottom, rgba(25, 15, 5, 0.7), rgba(25, 15, 5, 0.4) 110px, rgba(0, 0, 0, 0) 170px);
    position: relative;
    z-index: 1;
    min-height: 200px;
    @media (min-width: 540px) {
      min-height: 270px;
    }
    @media (min-width: 1200px) {
      min-height: 340px;
    }
    @media (min-width: 1400px) {
      min-height: 345px;
    }
  }
  .banner-content {
    background: linear-gradient(to right, rgba(25, 15, 5, 0.7), rgba(25, 15, 5, 0.4) 640px, rgba(0, 0, 0, 0) 720px);
    padding-top: @navbar-bottom-margin-mobile;
    padding-bottom: 0.25rem;
    @media (min-width: @grid-float-breakpoint) {
      padding-top: @navbar-bottom-margin;
      padding-bottom: 0.8rem;
    }
    padding-left: (@grid-gutter-width / 2);
    padding-right: (@grid-gutter-width / 2);

    @media (min-width: 450px) {
      padding-left: @grid-gutter-width;
      padding-right: @grid-gutter-width;
    }

    @media (max-width: 450px) {
      flex-grow: 1;
    }

    .breadcrumb,
    .breadcrumb a,
    .breadcrumb li:before,
    h1 {
      color: #fff;
      margin-bottom: 1rem;
    }
    .breadcrumb,
    h1,
    .description {
      max-width: 38rem;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
    }
    .description {
      color: #fff;
      a {
        text-decoration: underline;
        color: #ddd;
      }
    }
  }
}

.result-grid-item {
  background-color: #fff;
}

.result-grid-item {
  padding: 1rem;
  position: relative;
  .result-grid-image {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 0.5rem;
    padding-left: 5px;
    padding-right: 5px;
    height: 165px;
    text-decoration: none;

    &:focus {
      outline: none;
    }

    img {
      display: inline-block;
      vertical-align: bottom;
      max-height: 165px;
      width: auto;
    }
  }
}

.result-grid {
  @result-grid-border: desaturate(darken(@body-bg, 7%), 7%);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid @result-grid-border;
  border-left: 1px solid @result-grid-border;
  background-color: #fff;

  .result-grid-item {
    flex: 0 1 auto;

    position: relative;
    border-right: 1px solid @result-grid-border;
    border-bottom: 1px solid @result-grid-border;
    width: 20%;
    margin: 0;
    max-width: none;

    @media screen and (max-width: @screen-md-max) {
      width: 25%;
    }
    @media screen and (max-width: 900px) {
      width: 33.333%;
    }
    @media screen and (max-width: 660px) {
      width: 50%;
    }
    @media screen and (max-width: 380px) {
      width: 100%;
    }

    h3 > a {
      text-decoration: none;
    }
  }
}

.projects-page {
  h2 {
    margin-bottom: 1rem;
  }
}

.main-section {
  background-color: @body-bg - 8%;
  border-top: 1px solid (@body-bg - 20%);
  border-bottom: 1px solid (@body-bg - 20%);
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.main-section-inverse {
  color: @card-inverse-color;
  background: @card-inverse-bg;
  border-color: @card-inverse-border-color;
  h2,
  h3 {
    color: lighten(@card-inverse-color, 20%);
  }
}

.card-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  .card-grid-item {
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    width: 100%;
    padding-left: 0.25rem;
    padding-right: 0.25rem;

    .card {
      color: @text-color;
      margin-right: 0;
      margin-bottom: 0.5rem;
      height: 100%;
      .card-img-top {
        border-bottom: @card-border-width solid @card-border-color;
      }
      @media (max-width: 489px) {
        margin-bottom: 0.5rem;
      }
      &:hover,
      &:focus {
        text-decoration: none;
        border-color: (@body-bg - 90%);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      }
      .card-body {
        text-align: center;
      }
      .card-text {
        font-size: 0.95rem;
      }

      h2,
      h3 {
        margin-bottom: 0;
        font-weight: 500;
      }
    }
  }

  @media (min-width: 490px) {
    .card-grid-item .link-block {
      padding-right: 0;
      &:after {
        display: none;
      }
    }
    &.brand-grid .card-grid-item .link-block {
      padding-right: 1.5rem;
    }
  }
  @media (max-width: 489px) {
    margin-bottom: 1rem;
    .card-grid-item {
      .link-block {
        margin-bottom: 0;
        padding: 5px 15px 5px 5px; // list-group-item padding
        border-bottom-width: 0;
        border-radius: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        .card-img-top {
          width: 100px;
          height: 60px;
          border-radius: (@border-radius-base - 1px);
          border: none;
          margin-right: 1rem;
        }
        .card-text {
          margin-right: 1rem;
          line-height: 1.3;
        }

        .card-body {
          padding: 0;
          text-align: left;
          font-size: 1rem;
        }
        &:focus,
        &:hover {
          box-shadow: none;
          border-color: @list-group-border;
        }
      }
      &:first-child > .link-block {
        .border-top-radius(@list-group-border-radius);
      }
      &:last-child > .link-block {
        .border-bottom-radius(@list-group-border-radius);
        border-bottom-width: 1px;
      }
    }
  }
  @media (min-width: 490px) {
    .card-grid-item {
      width: 50%;
    }
  }
  @media (min-width: 775px) {
    .card-grid-item {
      width: 33.33%;
    }
  }
  @media (min-width: 1100px) {
    .card-grid-item {
      width: 25%;
    }
  }
  @media (min-width: 1300px) {
    .card-grid-item {
      width: 20%;
    }
  }
}

.classification-grid-section {
  .container-fluid {
    text-align: center;
  }
}

.classification-grid-wrapper {
  display: inline-block;
  @media (min-width: 490px) {
    width: 100%;
  }
  margin: 0;
  text-align: left;
  margin-bottom: -1rem;
  @media (max-width: 489px) {
    margin-bottom: -1rem;
  }
}

.classification-grid {
  justify-content: center;
  .card-grid-item {
    @media (min-width: 490px) {
      width: 50%;
      max-width: 330px;
    }
    @media (min-width: 650px) {
      width: 33.33% !important;
    }
  }
}

.brand-grid {
  .card-grid-item > a {
    padding: 1rem 1.5rem;
  }
  .brand-grid-img {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    min-height: 110px;
    height: 100%;

    img {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      width: auto;
      max-width: 100%;
      max-height: 100px;
    }
  }
  .card-body {
    padding-top: 0;
  }
}

.result-info {
  color: (@fontcolor + 60%);
}

.result-images {
  margin-top: 5px;
}

.result-category {
  .text-muted;

  a {
    .text-muted;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.category-page {
  .result-productcategory,
  .result-dot {
    display: none;
  }
}

.category-children-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  .category-children-filter-menu {
    padding: 0.75rem;
    padding-bottom: ~'calc(.75rem - 5px)';
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: @border-radius-base;
    text-align: center;
    .btn {
      margin-bottom: 5px;
    }
  }
  .btn.selected {
    background-color: rgba(255, 255, 255, 1);
    font-weight: bold;
    border-color: @brand-info;
    color: (@brand-info - 10%) !important;
    box-shadow: 0 0 5px @brand-info;
  }
}

.result-topic {
  .result-info strong {
    color: #333;
  }
  .pull-right {
    width: 110px;
    img {
      float: right;
      border: 1px solid #666;
    }
  }
}

.result-article {
  img {
    border: 1px solid #666;
  }
}
.result-article-small {
  img {
    margin-bottom: 5px;
    display: block;
  }
}

.result-article-card {
  h3 {
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.3;
  }
}

.add-to-cart-box {
  border: 1px solid @card-border-color;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: @border-radius-base;
  padding: @card-spacer-y @card-spacer-x;
  margin-bottom: @vspace;
  background-color: @body-bg;
  .product-availability {
    font-size: 1.3em;
    font-weight: 600;
    line-height: 1.4;
  }
  .product-price {
    font-size: 1.3em;
    font-weight: 600;
  }
  .free-shipping {
    line-height: 1.38;
    .free-shipping-text {
      font-size: 1.15em;
      font-weight: 600;
    }
  }
}

.in-stock {
  color: @state-success-text;
}

.not-in-stock {
  color: @state-danger-text;
}

.deferred-availability {
  color: @state-warning-text;
}

.out-of-stock {
  color: @state-danger-text;
}

.page-footer-newsletter-form {
  .container-fluid {
    max-width: 900px;
    min-width: 0;
  }
  margin-top: @vspace;
  padding: (@vspace * 2) @hspace @vspace @hspace;
  background-color: (@header-bg + 25%);
  color: #eee;

  .newsletter-form-text {
    line-height: 1.3;
    @media (max-width: @screen-sm) {
      text-align: center;
    }
    strong {
      text-transform: uppercase;
    }
  }
  .newsletter-form-field {
    margin-bottom: @vspace;
    .form-control {
      border: none;
    }
  }
}

footer {
  margin-bottom: @vspace;
  padding-top: @vspace;
  padding-bottom: @vspace;
  background-color: @header-bg;

  a {
    text-decoration: none;
  }
  hr {
    border-top-color: (@header-bg - 20%);
    border-bottom-color: (@header-bg + 20%);
  }
  .cell {
    float: left;
    width: 20%;
    position: relative;
    min-height: 1px;
    padding: @vspace @grid-gutter-width;

    @media (max-width: @screen-lg) {
      width: 25%;
    }

    @media (max-width: @screen-md) {
      width: 33%;
    }

    @media (max-width: @screen-sm) {
      width: 50%;
    }

    @media (max-width: @screen-xs) {
      width: auto;
      float: none;
    }

    div {
      padding: 1px 0;
    }
  }

  h3 {
    padding: 0;
    font-size: 21px;
    line-height: 1;
    color: darken(desaturate(#c19463, 35%), 10%);
    font-family: @font-family-serif;
  }

  a,
  a:hover,
  a:focus {
    color: @navbar-inverse-link-color;
  }
}

.errorlist {
  .text-danger;
  .bg-danger;
  padding: 3px 7px;
  border-radius: @border-radius-base;
  overflow: hidden;
  margin-bottom: 0;

  &.nonfield {
    margin-bottom: 1rem;
  }

  li {
    list-style: none;
    padding: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.card-product .warning,
.product-main .warning {
  .p;
  padding: 5px;
  border: 2px solid #555;
}

.description,
.panel,
.discussion-msg {
  iframe {
    max-width: 100%;
  }
}

/* discussions */
.discussion-msg {
  position: relative;
  padding: @vspace 17px 1px 17px;
  margin-bottom: 17px;
  background-color: #fff;
  .border-radius(7px);

  a.lightbox {
    display: inline-block;
  }

  a {
    -ms-word-break: break-all;
    word-break: break-all;

    // Non standard for webkit
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }

  @media screen and (max-width: 350px) {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.forum-topics {
  .result-category {
    display: none;
  }
}

.forum-topics,
.forum-categories {
  border-radius: @border-radius-base;
  overflow: hidden;

  thead td,
  thead th,
  tfoot td,
  tfoot th {
    padding: 1rem;
    font-weight: 600;
  }

  h3,
  h3 > a {
    margin: 0;
    font-weight: normal;
  }

  table {
    width: 100%;
  }

  td {
    vertical-align: top;
    border: none;
    border-bottom: 1px solid @brand-bg-color;
    padding: 1rem;
  }
}

.forum-post {
  padding: 7px 0;
}

.forum-post-img,
.review-img {
  display: inline-block;
  img {
    display: block;
    max-width: 100%;
    border-radius: @border-radius-base;
  }
}
.forum-post-img {
  width: 170px;
}

.discussion-pointer {
  position: absolute;
  height: 0;
  width: 0;
  top: 0;
  left: 30px;
  margin-top: -7px;
  border: 7px solid (@body-bg - 10%);
  border-bottom-color: #fff;
  border-top-style: none;
}

.discussion-info {
  position: relative;
  margin: 0 @hspace @vspace 3px;
  color: @text-muted;
}

.discussion-secondary-info {
  float: right;
  a {
    color: @text-muted;
  }
}
.discussion-author-extra {
  font-weight: 600;
  color: @text-color;
}

.discussion-author-img {
  float: left;
  width: 75px;
  height: 75px;
  padding-right: 13px;

  img {
    border-radius: 50%;
    max-width: 100%;
    border: 1px solid #fff;
    box-shadow: 0 0 2px #999;
  }
  @media (max-width: 500px) {
    display: none;
  }
}

.default-avatar,
.default-avatar.lazyloaded {
  opacity: 0.4;
}

.reply-author {
  background-color: #fff;
}

.reply-author .discussion-pointer {
  border-top-color: #fff;
}

.link-block {
  padding-right: 2rem;
  &:after {
    display: flex;
    align-items: center;
    padding: 0 0.75rem;
    height: 100%;
    font-family: 'icons';
    content: '\e007';
    font-size: 0.9rem;
    line-height: 1;
    color: rgba(125, 125, 125, 0.65);
    opacity: 0.75;
    position: absolute;
    right: 0;
    top: 0;
  }
  &:hover,
  &:focus {
    background-color: @list-group-hover-bg;
    &:after {
      color: rgba(125, 125, 125, 1);
    }
  }
}

.product-page {
  h1 {
    font-size: 26px;
    margin-bottom: 0.75rem;
  }
  .product-page-title {
    .labels {
      margin: 0.75rem 0;
    }
  }
  .brand-image {
    position: relative;
    text-align: center;
    display: block;
    margin: 0 auto;
    max-width: 400px;
    margin-bottom: @vspace;
    border: 1px solid @card-border-color;
    box-shadow: @card-box-shadow;
    padding: 0.75rem;
    border-radius: @border-radius-base;

    img {
      max-width: 85%;
      max-height: 140px;
    }
  }

  h2 > a {
    display: block;
  }
}

.brand-page {
  .brand-page-banner-wrapper {
    position: relative;
    min-height: 180px;
  }
  .brand-logo {
    border: 1px solid (@body-bg - 10%);
    background-color: #fff;
    text-align: center;
    padding: 1rem 0;
    box-shadow: none;
    position: relative;
    z-index: 3;

    img {
      margin: 0 auto 0 auto;
      max-height: 85px;
      max-width: 200px;
      width: auto;
    }

    @media (min-width: @lateral-margin-breakpoint) {
      border-radius: @border-radius-base;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    @media (min-width: 1000px) {
      position: absolute;
      bottom: 0;
      right: 0;
      display: inline-block;
      margin: 1rem;
      padding: 1rem 1.5rem;
      margin-top: 0;
      border: 1px solid @card-border-color;
      box-shadow: @card-box-shadow;
      img {
        max-height: 120px;
        max-width: 240px;
      }
    }
  }
}

body.checkout,
body.forum-category,
body.forum-index {
  .main-body {
    .container-fluid {
      max-width: 950px;
    }
  }
}

body.forum-topic {
  .main-body {
    .container-fluid {
      max-width: 1100px;
    }
  }
}

body.checkout {
  .cart-totals {
    padding-top: 0;
  }
}

.sticky-layout {
  @media (min-width: @grid-float-breakpoint) {
    position: sticky;
    top: 1rem;
  }
}

.apply-code-form-panel-body {
  margin: 0 1rem 1rem 1rem;
  .card-body {
    padding: 0.5rem;
  }
}
.apply-code-form {
  margin: 0 auto;
  max-width: 310px;
  width: 100%;
  .form-group {
    margin-bottom: 20px;
  }
}

.remove-gift-card-form,
.remove-code-form {
  .close {
    float: none;
  }
}

.product-compare-at-price {
  text-align: center;
  font-size: 15px;
}

.product-options-selector {
  margin-bottom: 0.65rem;

  .custom-select {
    margin-bottom: 5px;
  }
}

.product-in-stock {
  padding: 5px 0 0 0;
}

input.search-field-input,
.twitter-typeahead {
  display: block;
  float: left;
  border-radius: @border-radius-base;
  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

input.search-field-input {
  padding-right: 35px;
}

.search-field {
  position: relative;
}

// https://github.com/twitter/typeahead.js/pull/753
.twitter-typeahead,
.tt-hint,
.tt-input,
.tt-menu {
  width: 100%;
}
.tt-hint {
  color: #bbb !important;
}

.tt-input.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.search-field-button {
  display: block;
  float: left;
  border-left: none;
  margin: 0;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.3;

  svg {
    display: inline-block;
    vertical-align: middle;
    fill: @navbar-inverse-color !important;
  }

  &:focus,
  &:hover {
    border-left: none;
  }
}

// typeahed.js
.twitter-typeahead {
  .tt-menu {
    border-top: 1px solid #eee;
    padding: 5px 0;
    background-color: #fff;
    line-height: 1.9;
    width: 100%;
    min-width: 280px;
    color: @text-color;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    font-weight: 600;
    border-bottom-left-radius: @border-radius;
    border-bottom-right-radius: @border-radius;

    strong {
      font-weight: normal;
    }

    .tt-suggestion {
      margin: 0;
      padding: 8px 12px;
      line-height: 1.5;
      font-weight: normal;
      .tt-title {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .tt-suggestion-extra {
        font-weight: normal;
        font-size: 0.85em;
        color: @text-muted;
      }
      .media-body {
        // https://css-tricks.com/flexbox-truncated-text/
        min-width: 0;
      }
      .media-left {
        display: flex;
        align-items: center;
        width: 55px;
        text-align: center;
        padding: 2px 12px 0 2px;
        img {
          display: inline-block;
          margin: 0 auto;
          max-width: 100%;
          max-height: 39px;
        }
      }
    }
    .tt-cursor {
      background-color: @dropdown-link-hover-bg;
      color: @dropdown-link-hover-color;
      cursor: pointer;
    }
  }
}

.add-to-cart {
  display: table;
  width: 100%;
  padding-top: 5px;

  .qty-cell {
    display: table-cell;
    vertical-align: top;
    width: 50px;

    input {
      width: 45px;
      margin-right: 5px;
      text-align: center;
    }
  }

  .btn-cell {
    display: table-cell;
    vertical-align: top;
  }
}

/* cart */
.cart {
  .cart-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
    .media-left {
      // !important: safari mac os
      width: 100px !important;
      .media-object {
        margin: 0 auto;
      }
    }
    &:first-of-type {
      border-top: none;
    }
    &:last-of-type {
      border-bottom: none;
    }
  }
  .cart-item-title {
    margin-bottom: 0;
    font-size: 0.95rem;
    line-height: 1.5;
    font-weight: normal;
  }
}

.cart-page {
  .main-body {
    .container-fluid {
      max-width: 950px;
    }
  }

  .list-group {
    .list-group-item:first-child {
      border-top-left-radius: @card-border-radius;
      border-top-right-radius: @card-border-radius;
    }
  }
  .cart-totals {
    > * {
      strong {
        min-width: 90px;
      }
    }
  }
}

.js .update-qty-btn {
  display: none;
  text-align: right;
  padding-top: 3px;
}

.cart-item-qty {
  input {
    width: 65px;
    text-align: right;
  }
}

.cart-totals {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  > * {
    padding: 2px 4px;
    display: flex;
    align-items: flex-end;
    strong {
      flex-shrink: 1;
      padding-left: 1rem;
      min-width: 120px;
    }
  }
}

.cart-total {
  color: @fontcolor;
  font-size: 20px;
}

.table-responsive {
  border: 0;
  margin-bottom: 0;
}

/* forum */
blockquote {
  font-size: @font-size-base;
  padding: @vspace @hspace;
  margin: @vspace @hspace;
  color: @text-muted;
}

/* modules */
.module1 {
  padding: @vspace @hspace;
  background-color: @body-bg-darker;
  border-radius: @border-radius-base;
  margin-bottom: @vspace;
  @media (max-width: 450px) {
    margin-left: -(@grid-gutter-width / 2);
    margin-right: -(@grid-gutter-width / 2);
  }

  & > div:last-child {
    margin-bottom: 0;
  }
}

.breadcrumb-wrapper {
  width: 100%;
}
.breadcrumb {
  padding: 0;
  font-size: 0.88rem;
  margin-top: 0;
  margin-bottom: 0.8rem;
  color: @text-muted;
  a {
    color: @breadcrumb-color;
  }
}

.product-main-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: @vspace;
  &:hover {
    cursor: zoom-in;
  }
  @media (max-width: @grid-float-breakpoint-max) {
    margin-top: @vspace;
  }
}

.product-images,
.product-videos {
  display: flex;
  flex-wrap: nowrap;
  margin: 1rem auto;
  max-width: 400px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &.slick-initialized {
    overflow: visible;
  }

  .slick-prev,
  .slick-next {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 50%;
    margin-top: -16px;
    width: 32px;
    height: 32px;
    text-align: center;
    border-radius: 100%;
    border: 1px solid @btn-default-border;
    border-bottom-color: (@btn-default-border - 25%);
    background: #fff linear-gradient(to bottom, #fff 0%, #f6f6f6 100%);
    transition:
      background 0.15s ease-in,
      border 0.15s ease-in;
    box-shadow:
      0 1px 1px rgba(0, 0, 0, 0.2),
      0 0 0 3px rgba(255, 255, 255, 0.8);
    &:hover,
    &:focus {
      background: #fff;
      border-color: (@btn-default-border + 15%);
      box-shadow: 0 0 0 3px rgba(255, 255, 255, 1);
    }
    &:before {
      position: relative;
      opacity: 1;
      font-size: 16px;
      color: @text-color;
    }
  }
  .slick-prev {
    left: -15px;
  }
  .slick-next {
    right: -15px;
  }

  .product-image-wrapper,
  .product-video-wrapper {
    padding-right: 5px; // slick.js doesn't like margin on slick-itemsn
    padding-bottom: 2px; // box-shadows
  }

  .product-image,
  .product-video {
    position: relative;
    .product-video-play-btn {
      position: absolute;
      width: 35px;
      top: 37px;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.8;
      fill: #fff;
    }
    .product-video-duration {
      display: inline-block;
      position: absolute;
      left: 5px;
      top: 55px;
      padding: 0 5px;
      border-radius: 5px;
      font-size: 0.65rem;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      font-weight: 600;
    }
    &:hover,
    &:active,
    &:focus {
      opacity: 0.8;
    }
  }
  .product-image {
    cursor: zoom-in;
  }
}
.product-images {
  height: 53px;
}

.product-videos {
  .slick-prev,
  .slick-next {
    margin-top: -37px;
  }
}

.product-video-wrapper {
  width: 135px;
  min-width: 135px;
}
.product-video {
  display: block;
  overflow: hidden;
  height: 115px;
  border: 1px solid @card-border-color;
  border-radius: @card-border-radius;
  box-shadow: @card-box-shadow;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  .product-video-title {
    font-size: 11px;
    color: @text-color;
    text-align: left;
    overflow: hidden;
    padding: 3px 5px;
    height: 38px;
  }
}

.option-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .option-image {
    overflow: hidden;
    display: block;
    flex: 0 1 auto;
    position: relative;
    border-radius: 1rem;
    margin: 7px;
    width: 175px;
    height: 175px;
    @media (max-width: 650px) {
      margin: 3px;
      flex: 1 1 140px;
      height: 140px;
      max-width: 170px;
    }
    > a {
      transition: transform 0.25s;
      &:hover,
      &:focus {
        cursor: zoom-in;
        transform: scale(1.15);
      }
    }
    img {
      display: block;
      width: 100%;
    }
  }
}

.option-images {
  .option-image {
    a {
      display: block;
    }

    div {
      font-size: 0.92em;
      line-height: 1;
      text-align: center;
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      padding: 1rem 0.75rem;
      color: #fff;
      font-weight: 500;
      text-shadow:
        1px 1px 1px rgba(0, 0, 0, 0.6),
        1px 1px 5px rgba(0, 0, 0, 0.4);
      .text-overflow();
      background-color: rgba(0, 0, 0, 0.08);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      pointer-events: none;
    }
  }
}

.option-images-text {
  padding-top: 1rem;
  margin: 0 auto;
  text-align: center;
  .readable-width;
}

.checkout-content {
  padding-bottom: @vspace;
}
.checkout-cart .cell {
  .text-overflow;
}

.checkout-address,
.checkout-payment-method {
  overflow: hidden;
  float: left;
  margin-right: @hspace;
  margin-bottom: (@vspace * 2);
}
.checkout-payment-method {
  min-width: 240px;
  min-height: 155px;
  .media-body {
    width: auto;
  }
}
.checkout-address {
  min-width: 260px;
}
.payment-method-img {
  padding-top: 4px;
  max-width: 100%;
  max-height: 24px;
  margin: 0 auto;
}

.readable-width {
  max-width: 65em;
}

.js .product-section-content,
.js .manage-form {
  display: none;
}

.toggle {
  display: none;
}
.toggle.open,
.product-section-content.open,
.manage-form.open {
  display: block;
}

.product-section-content {
  padding-top: @line-height-computed;
  padding-bottom: @line-height-computed;
  overflow: hidden; // clear .checkout_box
}

.product-section-toggle {
  display: block;
  color: @fontcolor;
  cursor: pointer;
  overflow: hidden; // clear .checkout_box
  text-decoration: none;

  @media (max-width: 1300px) {
    clear: right; // clear .checkout-box
  }

  h2 {
    margin: 0;
  }

  [data-icon] {
    font-size: 18px;
    color: (@fontcolor + 20%);
    float: right;
    margin-top: -2px;
  }

  &.selected {
    [data-icon]:before {
      content: '\e006';
    }
  }
}

.more-target {
  position: relative;
  overflow-y: hidden;
}

.more-toggle {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: (@vspace * 4) @hspace 0 @hspace;
  text-decoration: none;
  .linear-gradient(~'bottom, #FFF 15%, rgba(255,255,255,0)');
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#03ffffff', endColorstr='#ffffff', GradientType=0);
  -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#03ffffff', endColorstr='#ffffff', GradientType=0);
}

.info-icon {
  fill: currentColor;
  vertical-align: text-bottom;
  width: 0.9rem;
  height: 0.9rem;
}

.social-widgets {
  .p;
  padding-top: 8px;
  min-height: 26px;

  .fb-like {
    max-width: 100px !important;
    overflow: hidden !important;
    margin-right: 5px;
  }
  & > * {
    vertical-align: top;
    float: left;
  }
}

.social-icons {
  padding-bottom: 0.5rem;

  a {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    margin-left: 0.75rem;
    margin-right: 0.75rem;

    &.facebook-icon > svg {
      fill: #3b5998;
    }

    &.twitter-icon > svg {
      fill: #1da1f2;
    }

    &.instagram-icon > svg {
      fill: #222;
    }

    &.pinterest-icon > svg {
      fill: #bd081c;
    }

    > svg {
      height: 24px;
      width: 24px; // ie11
    }
  }
}

.share-links {
  .dropdown-toggle > svg {
    margin-top: -2px;
  }
  display: flex;
  justify-content: center;
  .dropdown-menu li a > * {
    vertical-align: text-top;
  }
  a {
    > svg {
      height: 18px;
      width: 18px;
      margin-right: 5px;
    }
  }
}

footer .social-icons {
  padding: 0 0 5px 0;

  > a {
    margin: 5px 10px 0 0;
  }

  > a,
  > img {
    > svg {
      fill: @navbar-inverse-link-color !important;
      height: 21px;
      width: 21px; // ie11
    }
  }
}

.review {
  max-width: 600px;
  margin-bottom: (@vspace * 2);
  overflow: visible;

  .review-title {
    margin: 0 0 3px 0;
    color: @text-color;
    font-weight: 500;
    font-size: 1rem;
    .rating-stars {
      margin-right: 5px;
    }
  }

  .review-info {
    margin-top: 0;
    margin-bottom: 7px;
    color: @text-muted;
  }
  .internal-review {
    background-color: lighten(#f2aa01, 49%);
  }
  .review-reply {
    margin-left: (@hspace * 4);

    @media screen and (max-width: @screen-sm) {
      margin-left: (@hspace * 2);
    }
  }
}

.rating-stars,
.review-rating-stars {
  display: inline-block;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;

  [data-icon]:before {
    line-height: 20px !important;
    vertical-align: top !important;
  }
}
.star-on {
  color: #f2aa01;
}
.star-off {
  color: #dddddd;
}

.review-rating-text {
  display: inline-block;
  margin-left: 5px;
}

.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 180ms;
}

.carousel {
  position: relative;
  max-height: 360px;
  margin-bottom: 0;
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &.slick-initialized {
    overflow: visible;
  }

  .result-carousel-item {
    width: 220px;
    flex: 0 0 auto;
  }

  .slick-prev {
    left: 15px;
  }

  .slick-next {
    right: 15px;
  }

  .slick-next:before {
    left: 1px;
  }
  .slick-prev:before {
    right: 1px;
  }

  .result-grid-item {
    padding-top: 0;
    padding-bottom: 0;
  }

  .result-carousel-item {
    display: inline-block;
    padding-top: 0;
    &:first-child {
      padding-left: 5px;
      width: 225px;
    }
    .labels {
      top: 0;
    }
  }
}

.carousel {
  .slick-prev,
  .slick-next {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 50%;
    margin-top: -40px;
    width: 44px;
    height: 44px;
    text-align: center;
    border-radius: 100%;
    border: 1px solid @btn-default-border;
    border-bottom-color: (@btn-default-border - 25%);
    background: #fff linear-gradient(to bottom, #fff 0%, #f6f6f6 100%);
    box-shadow:
      0 1px 1px rgba(0, 0, 0, 0.2),
      0 0 0 5px rgba(255, 255, 255, 0.8);
    &:hover,
    &:focus {
      background: #fff;
      border-color: (@btn-default-border + 15%);
      box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.9);
    }
    &:before {
      position: relative;
      opacity: 1;
      font-size: 18px;
      color: @text-color;
    }
  }
}

.dropdown-menu {
  a:focus {
    outline: none;
  }
}

.estimated-times-form {
  .p;
  max-width: 200px;
}
.estimated-times-result {
  .padded;
  display: none;
  background-color: @color1;
  font-weight: 600;
}

.article-lead-img-credits {
  padding-right: @card-spacer-x;
}

.lead {
  font-size: 1.3em;
  font-weight: 400;
  line-height: 1.5;
}
.panel .lead {
  font-size: 15px;
  line-height: 1.2;
}

.pub-date {
  .small;
  color: #999;
}

.article-content {
  iframe {
    margin-bottom: @vspace;
    max-width: 90%;
  }
  iframe.align-right {
    margin-left: @hspace;
    margin-right: 0;
  }
  iframe.align-left {
    margin-right: @hspace;
  }

  h2 {
    clear: both; // clear .align-left .align-right
  }

  .align-left {
    float: left;
    margin-right: @hspace;
  }

  .align-right {
    float: right;
    margin-left: @hspace;
  }

  .align-center {
    margin: @vspace auto;
  }

  .align-left,
  .align-right {
    padding-top: 0;

    @media (max-width: @screen-sm) {
      margin: @vspace auto;
      display: table;
      float: none;
    }
  }
}

.gallery {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.article-img {
  .p;
  &.align-left,
  &.align-right {
    padding-top: 5px;
  }

  &.align-gallery {
    margin-right: 1rem;
  }

  &.align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  > a,
  img {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: @border-radius-base;
  }
}

.article-img-credits {
  font-style: italic;
  text-align: right;
  .text-muted;
  .small;
}

.article-lead-img-credits {
  .article-img-credits;
}
.article-lead-img-description {
  .article-img-credits;
}

.fileinput-btn {
  .btn;
  .btn-default;
  position: relative;
  display: block;
  padding: 1px 10px 4px 10px;
  cursor: pointer;
  float: left;
  clear: left;

  [data-icon] {
    font-size: 18px;
    vertical-align: center;
    pointer-events: none;
    cursor: pointer;
    margin-right: 5px;
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    filter: alpha(opacity=0);
    font-size: 100%;
    height: 100%;
    width: 100%;
    direction: ltr;
    cursor: pointer;
    line-height: 1;
  }
}

.uploads-wrapper {
  margin-bottom: (@line-height-computed / 2);
  max-width: 550px;
  overflow: hidden;
}

.uploads {
  display: block;
  float: left;
}

.js select[data-barrating-create] {
  visibility: hidden;
}

.br-widget {
  display: block;
  padding: 5px 13px;
  border: 1px solid #ddd;
  width: 190px;
  background-color: @input-bg;
  border: 1px solid @input-border;
  border-radius: @input-border-radius;
  .box-shadow(inset 0 1px 1px rgba(0,0,0,0.075));

  a {
    display: inline-block;
    text-decoration: none;
    &:before {
      display: inline-block;
      font-family: 'icons';
      line-height: 1.15;
      vertical-align: middle;
      text-align: middle;
      width: 32px;
      height: 34px;
      font-size: 28px;
      content: '\2a';
      .star-off;
    }

    &.br-selected:before,
    &.br-active:before {
      .star-on;
    }
  }
}

.has-error {
  background-color: @state-danger-bg;
  border-radius: @border-radius-base;
  padding: 5px;
  animation: fadein 0.8s;
  .br-widget {
    border: 1px solid @state-danger-text;
  }
}

.block {
  .card;
  .card-brand;
  .card-body;
}

.card[class^='description-'],
.card[class*=' description-'] {
  h3 {
    font-size: 1rem;
    font-weight: 600;
  }
  > .card-body {
    > p:last-child,
    > ul:last-child,
    > li:last-child {
      margin-bottom: 0;
    }
  }
}

.block-info,
.description-info {
  .card-brand-info;
  border: none;
  box-shadow: none;
  border-left: 3px solid @brand-info;
  background-color: fadeout(@brand-info, 92%);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  h1,
  h2,
  h3 {
    color: @brand-info;
  }
}
.block-warning,
.description-warning {
  .card-brand-warning;
  border: none;
  box-shadow: none;
  border-left: 3px solid @brand-warning;
  background-color: fadeout(@brand-warning, 90%);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  h1,
  h2,
  h3 {
    color: (@brand-warning - 15%);
  }
}
.block-success,
.description-success {
  .card-brand-success;
  border: none;
  box-shadow: none;
  border-left: 3px solid @brand-success;
  background-color: fadeout(@brand-success, 92%);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  h1,
  h2,
  h3 {
    color: @brand-success;
  }
}
.block-danger,
.description-danger {
  .card-brand-danger;
  border: none;
  box-shadow: none;
  border-left: 3px solid @brand-danger;
  background-color: fadeout(@brand-danger, 92%);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  h1,
  h2,
  h3 {
    color: @brand-danger;
  }
}

.block-default,
.description-default {
  .card-brand;
  border: none;
  box-shadow: none;
  border-left: 3px solid (@text-color + 20%);
  background-color: fadeout(@text-color, 92%);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  h1,
  h2,
  h3 {
    color: (@text-color + 20%);
  }
}

.label-liquidation {
  text-decoration: none;
  .label-warning;
}
.label-pro-use {
  text-decoration: none;
  .label-warning;
}
.label-discount {
  text-decoration: none;
  .label-danger;
}
.label-popular {
  text-decoration: none;
  .label-default;
}
.label-new {
  text-decoration: none;
  .label-success;
}
.label-recommended {
  text-decoration: none;
  .label-info;
}

h2.section-title {
  margin-top: 0;
  padding-top: 0;
}

a.close {
  text-decoration: none !important;
}
.close {
  font-family: 'helvetica neue', helvetica, arial, sans-serif;
  font-weight: normal;
  opacity: 0.4;
}
.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 502;
  overflow: hidden;
  position: fixed;
  background: #eee;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.home-banner {
  display: block;
  max-width: 100%;
  border-radius: @border-radius-base;
  img {
    display: block;
    max-width: 100%;
  }
}

.thumbnail-circle {
  border-radius: 50%;
  padding: 3px;
  background-color: #fff;
  border: 1px solid #bbb;
  box-shadow: 0 0 2px #ddd;
}

.panel-counter {
  font-size: 45px;
  line-height: 1;
  .text-muted;
}

.chzn-container {
  display: block !important;
}
.chzn-drop {
  min-width: 300px;
}
.chzn-container-multi .chzn-choices .search-field input {
  width: 100% !important;
}

.custom-select {
  border: 1px solid @input-border;
  border-radius: @input-border-radius;
  background: @input-bg;

  &.has-error {
    padding: 0;
    .form-control {
      box-shadow: 0 0 0 3px fadeout(@state-danger-text, 40%);
    }
  }

  .form-control {
    padding-right: 30px;
    &[disabled] {
      color: @text-muted;
    }
  }
}

.popover {
  text-align: left;
  min-width: 300px;
  font-size: 0.8rem;
  color: @text-color;
  @media (max-width: @screen-xs) {
    font-size: 0.7rem;
  }
}

.dz-button {
  display: none;
}
.dropzone {
  &.dz-drag-hover {
    opacity: 0.7;
    box-shadow: 0 0 5px @input-border-focus;
  }

  .dz-clickable,
  .dz-clickable * {
    cursor: pointer;
  }

  .dz-preview {
    position: relative;
    display: block;
    float: left;
    margin: 0 5px 1rem 0;
    border-radius: @border-radius-base;
    overflow: hidden;
    background-color: #eee;
    width: 110px;
    height: 110px;

    .dz-image {
      display: block;
    }

    img {
      display: block;
      max-width: 100%;
    }

    .dz-error-message {
      display: none;
      position: absolute;
      color: #fff;
      top: 0;
      left: 0;
      bottom: 0;
      padding: 30px 5px 5px 5px;
      font-size: 12px;
    }

    &.dz-error .dz-error-message {
      display: block;
      background-color: @brand-danger;
    }

    &.dz-file-preview .dz-image {
      background-color: #ccc;
      border-radius: @border-radius-base;
    }

    .dz-progress {
      opacity: 1;
      overflow: hidden;
      position: absolute;
      z-index: 1000;
      pointer-events: none;
      height: 7px;
      left: 50%;
      top: 50%;
      margin-top: -8px;
      width: 80px;
      margin-left: -40px;
      background: #fff;
      border-radius: @border-radius-base;

      .dz-upload {
        background: #333;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        transition: width 300ms ease-in-out;
      }
    }

    &.dz-processing {
      img {
        opacity: 0.35;
      }
      .dz-progress {
        opacity: 1;
      }
    }

    &.dz-complete {
      .dz-progress {
        opacity: 0;
      }
    }
    &.dz-complete:not(.dz-error) {
      img {
        opacity: 1;
      }
    }
  }

  .dz-remove {
    position: absolute;
    text-decoration: none;
    top: 5px;
    right: 5px;
    z-index: 100;
    border-radius: 100px;
    width: 28px;
    height: 28px;
    padding: 5px;
    font-size: 24px;
    line-height: 14px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    border: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
}

.index-slider {
  .clearfix;
  margin-bottom: 0;
  @media (min-width: @lateral-margin-breakpoint) {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .slick-slide {
    display: block;
    position: relative;
    width: 100%;
    max-height: 350px;
    picture,
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .slick-prev,
  .slick-next {
    transition: 0.2s all;
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .slick-prev:before,
  .slick-next:before {
    color: #fff;
    opacity: 0.8;
    text-shadow: 0 0 3px #000;
  }
  .slick-prev:hover:before,
  .slick-next:hover:before {
    opacity: 1;
  }
  .slide-description {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 8px 15px;
    font-size: 11px;
    color: #eee;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.2);
    border-top-left-radius: 5px;
  }

  @media (max-width: @grid-float-breakpoint) {
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    .slick-dots {
      bottom: -0.5rem;
      li {
        width: 1rem;
      }
    }
  }
}

.gift-card-page {
  padding: 20px;
  text-align: center;

  .gift-card-page-logo {
    width: 155px;
    margin: 0 auto 20px auto;
    display: block;

    img {
      max-width: 100%;
      display: block;
    }
  }

  .gift-card {
    position: relative;
    margin: 20px auto;
    max-width: 500px;
    color: #fff;

    .gift-card-bg {
      display: inline-block;
      border-radius: 10px;
      max-width: 100%;
      border: 3px solid #ddd;
    }

    .gift-card-code {
      position: absolute;
      bottom: 20px;
      left: 10%;
      width: 80%;
      max-width: 400px;
      display: inline-block;
      font-size: 30px;
      margin-bottom: 10px;
      font-weight: bold;
      line-height: 1;
      padding: 10px 20px;
      background-color: #fff;
      color: #333;
      border-radius: 3px;
    }

    .gift-card-amount {
      position: absolute;
      top: 0;
      right: 0;
      text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
      padding: 30px 50px;
      font-size: 45px;
      font-weight: bold;
    }

    @media (max-width: 450px) {
      .gift-card-code {
        font-size: 20px;
        left: 0;
        bottom: 10px;
        width: 100%;
        padding: 10px;
      }
      .gift-card-amount {
        font-size: 30px;
        padding: 20px 30px;
      }
    }
  }

  @media print {
    a[href].gift-card-page-logo:after {
      content: '' !important;
    }
    .gift-card-code {
      background-color: #fff !important;
    }
    .gift-card-amount {
      color: #fff !important;
      text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1) !important;
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }
  }
}

.gift-cards-banner {
  display: inline-block;
  max-width: 300px;
  img {
    .img-responsive;
  }
}

header {
  background-color: @header-bg;
}

.card-50 {
  column-count: 2;
  column-gap: (@grid-gutter-width / 2);
  position: relative;
  display: block;
  // fix bootstrap tooltip flicker with column-gap:2; in chrome
  // https://www.reddit.com/r/webdev/comments/1lej48/weird_hover_bug_in_chrome_using_css3_columns/
  //https://stackoverflow.com/questions/2946748/iphone-webkit-css-animations-cause-flicker
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  .card-50-item {
    width: 100%;
    display: inline-block;
    break-inside: avoid;
    overflow: hidden; // edge, break-inside:avoid;
    .card-body {
      // osmo calculator hidden content
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  @media (max-width: 900px) {
    column-gap: 0;
    column-count: 1;
    .card-50-item {
      overflow: inherit; // reverse edge break-inside:avoid; fix
    }
  }
}

.card-product-breadcrumb {
  margin-bottom: 0;
  border-bottom: none;
  border-top: none;
  box-shadow: none;
  border-radius: 0;

  .card-body {
    padding-top: @navbar-bottom-margin;
    padding-bottom: 0;
    @media (max-width: @grid-float-breakpoint) {
      padding-top: @navbar-bottom-margin-mobile;
    }
  }
}
.card-product-main {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  > .card-body {
    padding-top: 0;
  }
}

.img-responsive {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.pagination {
  > .active > a,
  > .active > span {
    color: @pagination-active-color !important;
  }
}

.mobile-cart-btn,
.text-icon-cart {
  .cart-badge {
    background-color: @btn-primary-bg;
    position: absolute;
    top: -2px;
    right: 0;
  }
}

.text-icon-cart {
  .cart-badge {
    top: 2px;
    right: auto;
    left: 24px;
    z-index: 1;
  }
}

.banner-page .banner-wrapper {
  margin-left: -@grid-gutter-width / 2;
  margin-right: -@grid-gutter-width / 2;
}

@media (max-width: @lateral-margin-breakpoint-max) {
  .filters-wrapper,
  .layout-card-group,
  .layout-card,
  .index-slider,
  .result-grid:not(.layout-card > .result-grid) {
    margin-left: -@grid-gutter-width / 2;
    margin-right: -@grid-gutter-width / 2;
    border-radius: 0;
  }
  .carousel {
    width: auto;
    margin-left: -20px;
    margin-right: -20px;
  }
  .layout-card-group {
    .layout-card {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .layout-card {
    border-left: none;
    border-right: none;
  }
}

@media (min-width: @lateral-margin-breakpoint-max) {
  .padded-layout-content {
    padding-left: (@grid-gutter-width / 2);
    padding-right: (@grid-gutter-width / 2);
  }
}

.link-text {
  color: inherit;
  &:hover,
  &:focus {
    color: (@text-color + 20%);
  }
}

.collapsing {
  .transition-duration(0.2s);
  opacity: 1;
  > * {
    opacity: 0;
  }
}
.collapse {
  .transition-property(~'height, opacity');
  visibility: visible;
  opacity: 0;
  &.in,
  &.show {
    opacity: 1;
  }
}

[bind='readmore'] {
  max-height: 800px;
}
[data-readmore] {
  // https://github.com/jedfoster/Readmore.js/issues/194
  transition: height 1ms;
  overflow: hidden;
}
[data-readmore-toggle] {
  @bg-color: #ffffff;
  position: relative;
  padding: 1rem;
  text-align: center;
  width: 100%;
  background-color: @bg-color;
  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, fade(@bg-color, 0%) 0%, fade(@bg-color, 99%) 100%);
    height: 100px;
  }
}
.card [data-readmore-toggle] {
  border-bottom-left-radius: @card-border-radius;
  border-bottom-right-radius: @card-border-radius;
}

body.blog {
  h1 {
    margin-top: 0.5rem;
    @media (max-width: @screen-sm) {
      font-size: 25px;
    }
  }
  .card-header {
    h1 {
      margin-top: 0;
    }
  }
  .article-content {
    h2,
    h3 {
      padding: 0.5rem @card-spacer-x;
      margin-right: -@card-spacer-x;
      margin-left: -@card-spacer-x;
      margin-top: 1.5rem;
      margin-bottom: 0.25rem;
    }
  }
}

.card-index-blog,
.card-index-forum {
  max-width: 500px;
}

.video-wrapper {
  width: 100%;
  max-width: 560px;
  margin-bottom: 1rem;
  .video {
    &:extend(.embed-responsive all);
    padding-bottom: 56.25%; // .embed-responsive-16by9
    &.embed-responsive-4by3 {
      padding-bottom: 75%;
    }
  }
}

.product-main {
  .video-wrapper:last-child {
    margin-bottom: 0;
  }
  .card-body table:last-child {
    margin-bottom: 0;
  }
}

.btn svg {
  fill: currentColor;
}

.btn-plain {
  .btn-link;
  color: @text-color;
  &:hover,
  &:focus,
  &:active {
    color: (@text-color - 40%);
    text-decoration: none;
    background-color: #f3f3f3;
  }
}

.btn-plain-muted {
  .btn-link;
  color: @text-muted;
  &:hover,
  &:focus,
  &:active {
    color: (@text-muted + 40%);
    text-decoration: none;
  }
}

.btn-showhidepassword {
  margin-right: 3px;
}

.filters-wrapper {
  position: relative;
  padding: 6px 6px 0 6px;
  border: 1px solid desaturate(darken(@body-bg, 10%), 10%);
  border-bottom: none;
  background-color: (@body-bg - 7%);
  border-top-left-radius: @border-radius-base;
  border-top-right-radius: @border-radius-base;
}

.filters {
  display: flex;
  flex-wrap: wrap;

  .dropdown {
    flex: 0 1 auto;
    display: inline-block;
    padding: 1px 4px;
    margin-bottom: 6px;

    .dropdown-menu {
      margin-top: -1px;
      left: 3px;
    }

    &.ordering-dropdown {
      margin-left: auto;
      .dropdown-menu {
        left: auto;
        right: 3px;
      }
    }
  }

  @media (max-width: @grid-float-breakpoint) {
    .dropdown {
      position: static;
      flex: 1 0 auto;

      > a {
        width: 100%;
      }

      &.ordering-dropdown .dropdown-menu,
      .dropdown-menu {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        top: auto;
        left: 9px;
        right: 9px;
      }
    }
  }
}

.filter-dropdown-handle {
  text-align: left;
  border: 1px solid (@body-bg - 35%);
  border-radius: @border-radius-base;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.02);
  background-color: rgba(255, 255, 255, 1);
  padding-right: 1rem;
  padding-left: 1rem;

  &.selected {
    background-color: rgba(255, 255, 255, 1);
    font-weight: bold;
    border-color: @input-border-focus;
    color: (@brand-info - 10%) !important;
    box-shadow: 0 0 0 3px fadeout(@input-border-focus, 40%);
    .text-muted {
      font-weight: normal;
    }

    &:hover,
    &:focus,
    &[aria-expanded='true'] {
      border-color: @input-border-focus;
    }
  }
  &:hover,
  &:focus,
  &[aria-expanded='true'] {
    background-color: rgba(255, 255, 255, 1);
    border-color: (@body-bg - 80%);
  }

  .caret {
    opacity: 0.7;
    color: inherit;
  }

  .handle-label {
    .text-muted;
    font-size: 0.9em;
    line-height: 1;
    text-align: left;
    font-weight: normal;
  }
}

.breadcrumb > li + li:before {
  font-size: 0.75em;
  vertical-align: text-top;
  padding: 0 2px 0 4px;
}

.documents-card {
  .btn {
    margin-bottom: 3px;
  }
  .thumbs {
    border-top: 1px solid @card-border-color;
    padding: 0.75rem;
  }
  .thumb {
    .card;
    margin: 0.25rem;
    display: inline-block;

    img {
      border-radius: @border-radius-base;
      max-width: 100%;
      @media (max-width: @screen-sm) {
        width: auto;
        max-height: 165px;
      }
    }
    &:hover,
    &:focus {
      opacity: 0.85;
    }
  }
  .list-group-item svg {
    display: inline-block;
    vertical-align: text-bottom;
    height: 18px;
    margin-right: 5px;
    fill: @body-color;
  }
}

.table .table {
  background-color: transparent;
}

.epoxy-calculator {
  background-color: desaturate((@body-bg + 5%), 30%);
  border: 1px solid (@body-bg - 10%);
  padding: 0.5rem 1rem;
  .form-control {
    display: inline-block;
  }
  .epoxy-result-wrapper {
    display: inline-block;
  }
}

.forum-aside {
  max-width: 500px;
  margin: 0 auto;
}
.braintree-hosted-fields-focused {
  @color-rgba: rgba(red(@input-border-focus), green(@input-border-focus), blue(@input-border-focus), 0.4);
  border-color: @input-border-focus;
  outline: 0;
  .box-shadow(~'inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 0 3px @{color-rgba}');
}

.paypal-fieldset {
  padding-top: 1rem;
}

.search-page {
  .search-page-card {
    > .card-body {
      padding-top: 0.5rem;
    }
  }

  .search-field {
    margin-top: 1rem;
  }
}

.search-result-list {
  margin-bottom: 2rem;
  .form-group {
    margin-bottom: 0.75rem;
  }
  .page-title {
    margin-bottom: 1.5rem;
  }
}
.search-category-selector {
  .form-group {
    max-width: none;
    border-bottom: 1px solid #ddd;
  }
  .radio {
    display: inline-block;
    margin: 0;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: #fff;

    label {
      padding: 0.75rem 1rem;
    }
    input[type='radio'] {
      display: none;
    }
    &:hover,
    &:focus {
      color: (@brand-info - 10%) !important;
    }
    &.selected {
      border-bottom-color: @brand-info;
      color: (@brand-info - 10%) !important;
      label {
        font-weight: 500;
      }
    }
  }
}

.white-popup {
  position: relative;
  background: #fff;
  padding: (@vspace * 3) (@hspace * 2);
  @media (max-width: @screen-xs) {
    padding-left: @hspace;
    padding-right: @hspace;
  }
  width: auto;
  max-width: 800px;
  margin: @vspace auto;
  border-radius: @border-radius-large;
}
.mfp-bg {
  background: lighten(@body-bg, 15%);
  opacity: 0.95;
  filter: alpha(opacity=95);
}
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close,
.mfp-close {
  color: #333;
  font-size: 40px;
  width: 50px;
  height: 50px;
}
.mfp-iframe-holder .mfp-close {
  top: -50px;
}
.mfp-iframe {
  border-radius: @border-radius-large;
}
.mfp-title {
  color: #333;
}
.mfp-counter {
  color: #999;
}

.mfp-arrow:focus,
.mfp-arrow:active {
  outline: 1px dotted #333;
}
.white-popup,
.mfp-figure:after {
  box-shadow: 0 0 10px rgba(150, 150, 150, 0.5);
}

.checkout-selection {
  ul,
  ol {
    padding-left: 1rem;
    margin-bottom: 0.5rem;
  }
  .btn-xs {
    vertical-align: baseline;
  }
}

.cc-icons {
  img {
    vertical-align: middle;
    height: auto;
  }
}

.brand-nav-pills {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  > li {
    float: none;
    > a {
      color: @text-color;
      padding: 0.25rem 0.75rem;
      margin: 4px;
      border-radius: 500px;
      background-color: @brand-bg-color;
      white-space: nowrap;
      &:hover,
      &:focus {
        background-color: darken(@brand-bg-color, 5%);
      }
    }
    &.active > a {
      &,
      &:hover,
      &:focus {
        background-color: @inverse-bg;
        color: #fff;
      }
    }
  }
}

.pca .pcatext {
  font-family: inherit;
  font-size: 12px;
}
