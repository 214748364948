.navbar {
  color:@navbar-inverse-color;
  margin-bottom:0;
  border:none;
  border-bottom:1px solid rgba(0,0,0,.4);
  box-shadow:0 1px 1px rgba(0,0,0,.2);

  > .container-fluid {
    // mobile navbar use absolute positining for logo and buttons
    position:relative;
  }

  .text-muted {
    color:(@navbar-inverse-color - 15%);
  }

  .navbar-titlebar {
    position: absolute;
    width:100%;
    text-align:center;
    top:-27px;
    left:0;
    right:0;
    font-size:.8rem;
    @media (max-width:1000px) {
      left:190px;
      width:75%;
    }
    svg {
      height:13px;
      text-align:center;
      display:inline-block;
      vertical-align:middle;
      opacity:0.8;
      width:32px;
      height:.85rem;
      line-height:normal;
    }
  }

  .mobile-search-btn,
  .mobile-cart-btn {
    display:none;
    padding:10px;

    svg {
      display:block;
      fill:@navbar-inverse-link-color;
    }
  }

  .navbar-search,
  .navbar-search .navbar-form,
  .navbar-search .navbar-form > .form-group,
  .navbar-search .search-field,
  .navbar-search .search-field-input,
  .navbar-search .twiter-typeahead {
    width:100% !important
  }

  .navbar-form {
    margin:0;
    border:none;
    box-shadow:none;

    .form-control {
      @navbar-inverse-placeholder-color: (@navbar-inverse-color - 15%);
      background-color:(@header-bg + 15%);
      border:1px solid transparent;
      box-shadow:none;
      color:@navbar-inverse-color;

      &::-webkit-input-placeholder {
        color:@navbar-inverse-placeholder-color;
      }
      &::-moz-placeholder {
        color:@navbar-inverse-placeholder-color;
      }
      &:-ms-input-placeholder {
        color:@navbar-inverse-placeholder-color;
      }
      &::input-placeholder {
        color:@navbar-inverse-placeholder-color;
      }
      &:hover {
        background-color:(@header-bg + 20%);
      }
      &:focus {
        background-color:#fff;
        color:@text-color;
        border-color:transparent;
      }
      &.hover {
        // !important for twitter typeahead
        background-color:(@header-bg + 20%) !important;
      }
      &.focus {
        // !important for twitter typeahead
        background-color:#fff !important;
        color:@text-color;
      }
    }
  }

  .navbar-nav {
    display:flex;
    align-items: flex-end;
    height:@navbar-height;

    > li {
      flex: 0 1 auto;
      justify-content:flex-end;

      > a {
        font-weight:600;
        white-space: nowrap;
        text-decoration:none;
        line-height:1.2;
        padding-top:10px;
        padding-bottom:14px;
        padding-left:.8rem;
        padding-right:.8rem;

        .caret {
          color:@navbar-inverse-color;
        }

        small {
          font-weight:normal;
        }

        svg {
          position:relative;
          display:inline-block;
          fill: @navbar-inverse-link-color;
        }

        &:hover,
        &:focus {
          svg {
            fill: @navbar-inverse-link-hover-color;
          }
        }

        &.text-icon-language {
          line-height:1;

          svg {
            top:3px;
          }
        }

        &.text-icon-cart {
          svg {
            top:5px;
          }
        }

      }
    }
  }

  .navbar-brand {
    margin-top:-34px;
    position:relative;
    z-index:999;
    img {
      width:175px;
      height:auto;
    }
  }

  .product-navbar-nav {
    padding-bottom:8px;

    .product-dropdown-toggle {
      @media (min-width:@grid-float-breakpoint) {
        padding-left:20px;
        padding-right:20px;
        padding-top:11px;
        padding-bottom:11px;
        background-color:(@header-bg - 25%);
        border-radius: @border-radius-base;
        color:(@navbar-inverse-link-color + 20%);
        transition:all .15s ease-in;

        // Remove background color from open dropdown
        &:hover,
        &:focus,
        &:active {
          background-color:(@header-bg - 50%);
          border-color:transparent;
          color:#fff;
        }
        > .open > .product-dropdown-toggle {
          &,
          &:hover,
          &:focus {
            background-color:(@header-bg - 50%);
            color: @navbar-inverse-link-color;
          }
        }
      }
    }
  }
}


@media (min-width: @grid-float-breakpoint) {
  .navbar {
    padding-top: @navbar-padding-top;
    position:relative;
    z-index:5;

    .navbar-collapse {
      display:flex !important;
      padding-left:0;
      visibility:visible;

      &.in {
        visibility:visible;
        opacity:1;
      }
      @media (min-width: @grid-float-breakpoint) {
        opacity:1;
      }

      .navbar-nav {
        float:none;
      }
      .product-navbar-nav {
        flex:1 1 auto;
        > li:first-of-type {
          flex:0 1 auto;
          text-align:center;
        }
      }
      .navbar-right {
        flex:0 1 auto;
        align-self: flex-end;
        justify-content: flex-end;
      }
    }
  }
}

@media (min-width: 1100px) {
  .navbar {
    .container-fluid {
      padding-left:2rem;
      padding-right:2rem;
    }
  }
}
