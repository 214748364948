.js .dropdown-menu {
  display: none; // none by default, but block on "open" of the menu
}

.open > .dropdown-menu,
.dropdown-menu.open {
  display:block;
}

.dropdown-menu {
  > li > a,
  > li > .dropdown-menu-body {
    text-decoration:none;
    cursor:pointer;
    padding:7px 30px 7px 20px;
  }
}

.dropdown-menu-arrow:before {
  position: absolute;
  top: -7px;
  left: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.dropdown-menu-arrow:after {
  position: absolute;
  top: -6px;
  left: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: '';
}

.dropdown-menu-arrow.dropdown-menu-right:before {
  right: 9px;
  left:auto;
}

.dropdown-menu-arrow.dropdown-menu-right:after {
  right: 10px;
  left:auto;
}

.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}
.dropdown-submenu > .dropdown-menu {
  display: block;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}

.dropdown-submenu > a:after {
  content: "";
  float: right;
  margin-top: 6px;
  margin-right: -15px;
  border-left: 4px dashed;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;

  @media (max-width: @grid-float-breakpoint-max) {
    float:none;
    vertical-align:middle;
    display:inline-block;
    margin-left:6px;
    margin-top:2px;
    margin-right:0;
    border-top: 4px solid;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
  }
}

.dropdown-menu-right .dropdown-submenu > a:after,
.navbar-right .dropdown-submenu > a:after {
  float: left;
  border-left: none;
  margin-left: -10px;
  margin-right: 0;
  border-right: 4px dashed;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent
}

.dropdown-menu-inverse,
.dropdown-menu-inverse .dropdown-menu {
  background-color:@header-bg;
  color:@navbar-inverse-link-color;
  > li > a {
    color:@navbar-inverse-link-color;
    &:hover {
      background-color:(@header-bg - 10%);
      color:#fff;
    }
  }
  .divider {
    background-color:(@header-bg + 30%);
  }
}
