.mt-0 {
  margin-top:0 !important;
}
.ml-0 {
  margin-left:0 !important;
}
.ml-1 {
  margin-left:.25rem !important;
}
.ml-2 {
  margin-left:.5rem !important;
}
.ml-3 {
  margin-left:.75rem !important;
}
.ml-4 {
  margin-left:1rem !important;
}
.mr-0 {
  margin-right:0 !important;
}
.mb-0 {
  margin-bottom:0 !important;
}
.mb-1 {
  margin-bottom:.25rem !important;
}
.mb-2 {
  margin-bottom:.5rem !important;
}
.mb-3 {
  margin-bottom:.75rem !important;
}
.mb-4 {
  margin-bottom:1rem !important;
}
.pb-0 {
  padding-bottom:0 !important;
}
.pt-0 {
  padding-top:0 !important;
}
.pt-1 {
  padding-top:.25rem !important;
}
.pt-2 {
  padding-top:.5rem !important;
}
.pt-3 {
  padding-top:.75rem !important;
}
.pb-1 {
  padding-bottom:.25rem !important;
}
.pb-2 {
  padding-bottom:.5rem !important;
}
.pb-3 {
  padding-bottom:.75rem !important;
}
.mt-1 {
  margin-top:.25rem !important;
}
.mt-2 {
  margin-top:.5rem !important;
}
.mt-3 {
  margin-top:.75rem !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: .25rem !important;
}
.p-2 {
  padding: .5rem !important;
}
.p-3 {
  padding: .75rem !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: .25rem !important;
}
.m-2 {
  margin: .5rem !important;
}
.m-3 {
  margin: .75rem !important;
}
