@media (min-width:@grid-float-breakpoint) and (max-width:1100px) {
  .navbar {
    .navbar-brand {
      padding-right:.5rem;
    }
    .navbar-form {
      padding-right:.5rem;
      padding-left:.5rem;
    }
    .navbar-brand {
      margin-top:-28px;
      img {
        width:160px;
      }
    }
  }
}

@media (max-width:900px) {
  .navbar {
    .navbar-nav {
      > li {
        > a {
          padding-left:.6rem;
          padding-right:.6rem;
        }
      }
    }
  }

}
