@font-face {
  font-family: "icons";
  src:url("../fonts/icons.eot?v=2");
  src:url("../fonts/icons.eot?v=2#iefix") format('embedded-opentype'),
    url("../fonts/icons.woff?v=2") format('woff'),
    url("../fonts/icons.ttf?v=2") format('truetype'),
    url("../fonts/icons.svg?v=2#icons") format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
  font-family: "icons";
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align:middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-icon="cart"]:before {content: "\e001";}
[data-icon="arrow-down"]:before {content: "\e000";}
[data-icon="arrow-up"]:before {content: "\e006";}
[data-icon="gear"]:before {content: "\e003";}
[data-icon="plus"]:before { content: "\2b"; }
[data-icon="minus"]:before { content: "\2d"; }
[data-icon="search"]:before { content: "\e002"; }
[data-icon="arrow-left"]:before { content: "\e004"; }
[data-icon="arrow-right"]:before { content: "\e007"; }
[data-icon="list"]:before { content: "\e008"; }
[data-icon="close"]:before { content: "\78"; }
[data-icon="link"]:before { content: "\21"; }
[data-icon="star"]:before { content: "\2a"; }
[data-icon="camera"]:before { content: "\23"; }
[data-icon="home"]:before { content: "\5e"; }
[data-icon="zoom"]:before { content: "\40"; }
