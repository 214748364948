
.media {
  display:flex;
}
.media-left,
.media-right {
  flex:0 0 auto;
}
.media-right {
  order:3;
  padding-left:@hspace;
}
.media-left {
  padding-right:@hspace;
}
.media-body {
  display:inline-block;
  flex:1 1 auto;
  width:auto;
}

@media (max-width:500px) {
  .media.media-responsive {
    flex-direction: column;
    .media-right {
      padding-left:0;

    }
    .media-left {
      padding-right:0;
    }
    .media-right,
    .media-left {
      align-self: center;
      padding-bottom:@vspace;
    }
  }
}
