.alert {
  animation: fadein 0.6s;

  a:not(.btn) {
    color: #fff;
    &:focus,
    &:hover {
      color: #fff;
    }
  }

  .alert-link {
    font-weight: normal;
    text-decoration: underline;
  }

  svg {
    fill: currentColor;
  }

  .close {
    color: #fff;
    opacity: 0.7;
    text-shadow: none;
    font-size: 26px;
    line-height: 16px;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.alert-danger {
  .alert-variant(@brand-danger, (@brand-danger - 20%), #fff);
}

.alert-christmas {
  .alert-variant((@brand-danger - 15%), (@brand-danger - 20%), #fff);
}

.alert-info {
  .alert-variant(@brand-info, (@brand-info - 20%), #fff);
}

.alert-success {
  .alert-variant(@brand-success, (@brand-success - 20%), #fff);
}

.alert-warning {
  .alert-variant(@brand-warning, (@brand-warning - 20%), #fff);
}

.alert-outline-variant(@border; @text-color) {
  background-color: transparent;
  border: 2px solid @border;
  a {
    color: @link-color;
    text-decoration: underline;
    &:hover,
    &:focus {
      color: (@link-color + 30%);
    }
  }
}

.alert-outline-danger {
  .alert-outline-variant(@brand-danger, @brand-danger);
}

.alert-outline-info {
  .alert-outline-variant(@brand-info, (@brand-info - 25%));
}

.alert-outline-success {
  .alert-outline-variant(@brand-success, @brand-success);
}

.alert-outline-warning {
  .alert-outline-variant(@brand-warning, @brand-warning);
}

// django uses error instead of danger
.alert-error:extend(.alert-danger) {
}

.alert-default {
  .alert-variant(@alert-default-bg; @alert-default-border; @alert-default-text);
  a:not(.btn) {
    color: inherit;
    &:hover,
    &:focus {
      color: inherit;
    }
  }
}

.alert-dark {
  .close {
    color: #fff;
    opacity: 0.5;
  }
  a {
    color: #fff;
  }
}
.alert-black {
  .alert-variant((@header-bg - 25%); (@header-bg - 25%); (@navbar-inverse-link-color + 25%));
}

.messages {
  margin-bottom: 1rem;
  .alert {
    margin-bottom: 0;
    & + .alert {
      margin-top: 0.5rem;
    }
  }
}

.main-header ~ .messages {
  margin-bottom: 0;
  .alert-inner {
    .container-fluid;
  }
}
.main-header ~ .messages .alert,
.main-header ~ .alert {
  border-radius: 0;
  .alert-inner {
    text-align: center;
  }
  &:first-child {
    border-top: 5px solid rgba(30, 30, 30, 0.1);
  }
}
.alert-top {
  animation: none;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  border-radius: 0;
  border: none;
}

.alert-top .alert-inner,
.messages .alert-inner {
  min-width: 0;
  @media (max-width: 450px) {
    padding-left: 0;
    padding-right: 0;
  }
}
