.center {
  text-align:center;
}
.right {
  text-align:right;
}
.left {
  text-align:left;
}

.js .js-hidden {display:none;}

.table-unstyled {
  padding:0;
  margin:0;
  background:none;
  border-collapse: collapse;
  border-spacing: 0;
  border:none;

  tbody, thead, tr, td, th {
    padding:0;
    border:none;
    background:none;
  }
}
