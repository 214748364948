@btn-default-bg: #fff;
@btn-default-border: (@body-bg - 35%);
@btn-default-color: @text-color;

@btn-primary-color:#FFF;
@btn-primary-bg:(#4E7D0E + 40%);
@btn-primary-border:(@btn-primary-bg - 5%);


// Button variants
// -------------------------
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
.button-variant(@color; @background; @border) {
  .button-size(@padding-base-vertical; @padding-base-horizontal * 1.8; @font-size-base; @line-height-base; @btn-border-radius-base);
  color: @color;
  text-shadow:0 -1px 0 (@background - 30%);
  background-color: @background;
  background-image:linear-gradient(to bottom, lighten(@background, 3%), darken(@background, 2%));
  border-color: @border;
  border-bottom-color: darken(@border, 10%);
  box-shadow:inset 0 1px 0 rgba(255,255,255,.2), inset 0 -1px 0 rgba(0,0,0,.02);
  transition: color .15s, background-color .15s, background-image .15s, border-color .15s;

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open .dropdown-toggle& {
    color: @color;
    background-color: lighten(@background, 5%);
    background-image:none;
    border-color: lighten(@border, 5%);
  }
  &:active,
  &.active,
  .open .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: @background;
          border-color: @border
    }
  }
}

button {
  color:@fontcolor;
}

.btn {
  text-decoration:none;

  &:active,
  &.active {
    .box-shadow(none);
  }
}

.btn-xs {
  font-size:11px;
  line-height:1.4;
  padding:2px 7px;
}


.btn-inverse {
  background:@btn-inverse-bg;
  border-color: @btn-inverse-border-color;
  border-bottom-color: darken(@btn-inverse-border-color, 5%);
  color: @btn-inverse-color;

  &:focus,
  &:hover {
    background:lighten(@btn-inverse-bg, 2%);
    border-color: @btn-inverse-border-color;
    color: lighten(@btn-inverse-color, 3%);
  }
}

.btn {
  font-weight:600;
}

.btn-default,
.btn-link {
  text-shadow:none;
  font-weight:normal;
}
.btn-default {
  box-shadow:0 1px 0 rgba(0,0,0,.02);
}

.btn > svg {
  vertical-align:middle;
  fill:currentColor;
}

.btn-unstyled {
  &:extend(.btn-link);
  padding:0;
}
