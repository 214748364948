//
// Base styles
//

// Card variants

.card-variant(@background, @border) {
  background-color: @background;
  border-color: @border;

  .card-header,
  .card-footer {
    background-color: transparent;
  }
}

.card-outline-variant(@color) {
  background-color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: @color;

  .card-header,
  .card-footer {
    background-color: transparent;
    border-color: @color;
  }
  .card-icon {
    fill: @color;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: @card-bg;
  border: @card-border-width solid @card-border-color;
  border-radius: @card-border-radius;
  box-shadow: @card-box-shadow;

  > a {
    display: block;
    color: @text-color;
    text-decoration: none;
  }
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: @card-spacer-y @card-spacer-x;
}

.card-title {
  margin-bottom: @card-spacer-y;
}

.card-subtitle {
  margin-top: -(@card-spacer-y / 2);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  &,
  &:focus,
  &:hover {
    text-decoration: none;
  }
  + .card-link {
    margin-left: @card-spacer-x;
  }
}

.card {
  > .list-group-flush {
    margin-bottom: 0;
    > .list-group-item {
      padding-left: @card-spacer-x;
      padding-right: @card-spacer-x;
    }
  }
  > .list-group:first-child {
    .list-group-item:first-child {
      border-top-left-radius: @card-border-radius;
      border-top-right-radius: @card-border-radius;
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      border-bottom-left-radius: @card-border-radius;
      border-bottom-right-radius: @card-border-radius;
    }
  }
}

//
// Optional textual caps
//

.card-header {
  padding: @card-spacer-y @card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  background-color: @card-cap-bg;
  border-bottom: @card-border-width solid @card-border-color;

  &:first-child {
    border-radius: @card-inner-border-radius @card-inner-border-radius 0 0;
  }
}

.card-footer {
  padding: @card-spacer-y @card-spacer-x;
  background-color: @card-cap-bg;
  border-top: @card-border-width solid @card-border-color;

  &:last-child {
    border-radius: 0 0 @card-inner-border-radius @card-inner-border-radius;
  }
}

//
// Header navs
//

.card-header-tabs {
  margin-right: -(@card-spacer-x / 2);
  margin-bottom: -@card-spacer-y;
  margin-left: -(@card-spacer-x / 2);
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -(@card-spacer-x / 2);
  margin-left: -(@card-spacer-x / 2);
}

.layout-card {
  border: none;
}

//
// Background variations
//

.card-primary {
  .card-variant(@brand-primary, @brand-primary);
}
.card-success {
  .card-variant(@brand-success, @brand-success);
}
.card-info {
  .card-variant(@brand-info, @brand-info);
}
.card-warning {
  .card-variant(@brand-warning, @brand-warning);
}
.card-danger {
  .card-variant(@brand-danger, @brand-danger);
}
.card-main {
  .card-variant(@body-bg-darker, @body-bg-darker);
  box-shadow: none;
}
.card-solid-muted {
  .card-variant(fadeout(desaturate(@body-bg, 6%), 40%), transparent);
}

// Remove all backgrounds
.card-outline-primary {
  .card-outline-variant(@btn-primary-bg);
}
.card-outline-info {
  .card-outline-variant(@btn-info-bg);
}
.card-outline-success {
  .card-outline-variant(@btn-success-bg);
}
.card-outline-warning {
  .card-outline-variant(@btn-warning-bg);
}
.card-outline-danger {
  .card-outline-variant(@btn-danger-bg);
}

//
// Blockquote
//

.card-blockquote {
  padding: 0;
  margin-bottom: 0;
  border-left: 0;
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: @card-img-overlay-padding;
}

.card-img {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  height: auto;
}

// Card image caps
.card-img-top {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  height: auto;
  border-top-left-radius: @card-inner-border-radius;
  border-top-right-radius: @card-inner-border-radius;
}

.card-img-bottom {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  height: auto;
  border-bottom-left-radius: @card-inner-border-radius;
  border-bottom-right-radius: @card-inner-border-radius;
}

// Card deck

@media (max-width: @screen-sm-max) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -@card-deck-margin;
    margin-left: -@card-deck-margin;

    .card {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: @card-deck-margin;
      margin-left: @card-deck-margin;
    }
  }
}

//
// Card groups
//

@media (max-width: @screen-sm-max) {
  .card-group {
    display: flex;
    flex-flow: row wrap;

    .card {
      flex: 1 0 0%;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .card-img-top {
          border-top-right-radius: 0;
        }
        .card-img-bottom {
          border-bottom-right-radius: 0;
        }
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .card-img-top {
          border-top-left-radius: 0;
        }
        .card-img-bottom {
          border-bottom-left-radius: 0;
        }
      }

      &:not(:first-child):not(:last-child) {
        border-radius: 0;

        .card-img-top,
        .card-img-bottom {
          border-radius: 0;
        }
      }
    }
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: @card-columns-margin;
  }

  @media (min-width: @screen-sm-max) {
    column-count: @card-columns-count;
    column-gap: @card-columns-gap;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

//
// Inverse text within a card for use with dark backgrounds
//

.card-inverse {
  color: @card-inverse-color;
  background: @card-inverse-bg;
  border-color: @card-inverse-border-color;

  > .card-header,
  > .card-body,
  > .card-footer {
    background: @card-inverse-bg;
    border-color: @card-inverse-border-color;
    h2,
    h3 {
      color: lighten(@card-inverse-color, 20%);
    }
  }
  .card-link {
    &:focus,
    &:hover {
      color: @card-inverse-link-hover-color;
    }
  }
}

.card-grid-inverse {
  .card {
    border-color: lighten(@card-inverse-border-color, 5%);
    border-bottom-color: @card-inverse-border-color;
    background-color: lighten(@card-inverse-bg, 5%);
    .card-header,
    .card-body,
    .card-footer {
      background-color: transparent;
      border-color: lighten(@card-inverse-border-color, 5%);
      color: lighten(@card-inverse-color, 15%);
    }
  }
  a.card {
    transition: all 0.1s ease-in;
    &:hover,
    &:focus {
      background-color: lighten(@card-inverse-bg, 8%);
      border-color: @card-inverse-border-color;
      box-shadow: none;
      .card-header,
      .card-body,
      .card-footer {
        border-color: lighten(@card-inverse-border-color, 10%);
      }
    }
  }
}

.card {
  margin-bottom: 1rem;

  > .card-header {
    & + .card-body,
    & + * > .card-body:first-child {
      padding-top: 0;
    }
  }
}

.card-brand {
  .card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;

    .card-title {
      display: inline-block;
    }

    > .btn {
      margin-left: 10px;
    }

    > h2,
    > h3 {
      margin-bottom: 0;
    }
    > h1 {
      margin-bottom: 4px;
    }
  }

  .card-header + .card-body,
  .card-body + .card-body {
    padding-top: 0;
  }

  .card-title {
    color: @headings-color;
    > a {
      text-decoration: none;
      color: @headings-color;
    }
  }
}

.card-brand-danger {
  > .card-header {
    border-top-color: @brand-danger;
  }
  .card-title {
    color: @brand-danger;
    > a {
      color: @brand-danger;
    }
  }
}

.card-brand-success {
  > .card-header {
    border-top-color: @brand-success;
  }
  .card-title {
    color: @brand-success;
    > a {
      color: @brand-success;
    }
  }
}

.card-brand-warning {
  > .card-header {
    border-top-color: @brand-warning;
  }
  .card-title {
    color: @brand-warning;
    > a {
      color: @brand-warning;
    }
  }
}

.card-brand-info {
  > .card-header {
    border-top-color: @brand-info;
  }
  .card-title {
    color: @brand-info;
    > a {
      color: @brand-info;
    }
  }
}

.card-table {
  &:last-child {
    margin-bottom: 0;
  }
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding-left: @card-spacer-x;
        padding-right: @card-spacer-x;

        ol,
        ul {
          padding-left: 1rem;
        }
        ol:last-child,
        ul:last-child {
          margin-bottom: 0;
          padding-left: 1rem;
        }
      }
    }
  }
}

.card-body > p:last-child {
  margin-bottom: 0;
}

.card {
  .alert {
    margin-bottom: 0;
  }
  .card-header {
    border-bottom: 0;
  }
  .card-header h1,
  .card-header h2,
  .card-header h3 {
    margin-bottom: 0;
  }
}

.card-lg {
  border: none;
  > .card-header,
  > .card-body,
  > .card-footer {
    padding: @card-lg-spacer-y @card-lg-spacer-x;
    @media (max-width: @spacer-x-mobile-breakpoint) {
      padding-left: @card-spacer-x;
      padding-right: @card-spacer-x;
    }
  }
}

.card-alert-top {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  & + .card-alert-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
