.border-radius(...) {
  -webkit-border-radius: @arguments;
     -moz-border-radius: @arguments;
          border-radius: @arguments;
}

.box-shadow(...) {
  -webkit-box-shadow: @arguments;
     -moz-box-shadow: @arguments;
          box-shadow: @arguments;
}

.background-clip(...) {
  -webkit-background-clip: @arguments;
     -moz-background-clip: @arguments;
          background-clip: @arguments;
}

.opacity(@opacity) {
  opacity: @opacity;
  // IE8 filter
  @opacity-ie: (@opacity * 100);
  filter: ~"alpha(opacity=@{opacity-ie})";
}

.linear-gradient(...) {
  background-image: -webkit-linear-gradient(@arguments); // Safari 5.1+, Chrome 10+
  background-image: -moz-linear-gradient(@arguments); // FF 3.6+
  background-image: -ms-linear-gradient(@arguments); // IE10
  background-image: -o-linear-gradient(@arguments); // Opera 11.10
  background-image: linear-gradient(@arguments); // The standard
}

.radial-gradient(...) {
  background-image: -webkit-radial-gradient(@arguments);
  background-image: -moz-radial-gradient(@arguments);
  background-image: -ms-radial-gradient(@arguments);
  background-image: -o-radial-gradient(@arguments);
  background-image: radial-gradient(@arguments);
}
