@media (max-width: @grid-float-breakpoint-max) {
  .navbar {
    height:@navbar-mobile-height;

    .navbar-toggle {
      position:absolute;
      top:7px;
      left:8px;
      z-index:2;
      border:none;
    }

    .mobile-search-btn,
    .mobile-cart-btn {
      display:inline-block;
      position:absolute;
      top:10px;
    }
    .mobile-cart-btn {
      right:3px;
    }
    .mobile-search-btn {
      top:13px;
      right:52px;
      @media (max-width:360px) {
        right:45px;
      }
    }
    .navbar-titlebar {
      display:none;
    }
    .navbar-header {
      width:100%;

      .navbar-brand {
        position: absolute;
        z-index: (@zindex-navbar + 1);
        margin-left: auto;
        margin-right: auto;
        margin-top:-5px;
        left: 0;
        right: 0;
        padding-left:0;
        padding-right:0;
        width:135px;
        img {
          width:135px;
        }
      }
    }

    .navbar-collapse {
      position:relative;
      overflow:visible; // to see witter typeahead menu
      z-index: @zindex-navbar;
      margin-top:@navbar-mobile-height;
      margin-left:-@grid-gutter-width / 2;
      margin-right:-@grid-gutter-width / 2;
      background-color:@header-bg;
      border-top:1px solid (@header-bg + 15%);
      box-shadow:0 1px 2px rgba(0,0,0,.4);
      > .navbar-nav:first-of-type {
        margin-top:1rem;
        padding-top:.5rem;
      }
      > .navbar-nav:last-of-type {
        padding-bottom:.5rem;
      }
    }

    // prevents flickering during .navbar-collapse animation
    .navbar-form {
      visibility:hidden;
    }
    .collapse.in .navbar-form {
      visibility:visible;
    }

    .navbar-form {
      padding:0;
    }

    .navbar-search {
      position:absolute;
      padding:1rem;
      bottom:0;
      border-top:1px solid rgba(0,0,0,.4);
      &:before {
        content:"";
        position:absolute;
        top:0;
        left:0;
        right:0;
        height:1px;
        border-top:1px solid (@header-bg + 15%);
      }

      .form-control {
        // !important for twitter typeahead
        background-color:#fff !important;
        color:@text-color;
      }
    }

    .navbar-nav {
      height:auto;
      align-items: stretch;

      > li > a {
        display:block;
      }

      .text-icon-link {
        svg {
          display:none;
        }
      }

      &.navbar-right {
        flex-direction:column;
        text-align:left;
        margin-top:0;
      }

      &.product-navbar-nav {
        margin-top:0;
        margin-bottom:0;
        padding-top:0;
        padding-bottom:0;
      }

      .dropdown-menu li > a,
      > li > a {
        font-weight:normal;
        padding:15px 20px;
        line-height:1.2;
      }

      > .open > a {
        &:focus,
        &:hover {
          background-color:(@header-bg + 15%);
        }
      }

      .dropdown {
        width:100%;

        &.open .dropdown-menu {
          padding:0;
          border-left:2px solid @navbar-inverse-color;
          background-color:(@header-bg + 15%);

          > li > a {
            padding-left:25px;
            &:focus,
            &:hover {
              background-color:(@header-bg + 25%);
            }
          }
          > .divider {
            background-color:(@header-bg + 30%);
          }
        }

        &.open .dropdown-submenu {
          .dropdown-menu {
            > li > a {
              background-color:(@header-bg + 20%);
              padding-left:35px;
              &:focus,
              &:hover {
                background-color:(@header-bg + 30%);
              }
            }
          }
          > a:after {
            margin-right:0;
          }
        }
      }

    }
  }
}


.navbar-toggle .icon-bar {
  transition: opacity .2s ease-in-out, transform .2s ease-in-out;
}

.navbar-toggle:not(.collapsed) .icon-bar:nth-of-type(1) {
  transform: rotate(45deg) translate(4px, 4.5px);
}

.navbar-toggle:not(.collapsed) .icon-bar:nth-of-type(2) {
  background-color: transparent;
}

.navbar-toggle:not(.collapsed) .icon-bar:nth-of-type(3) {
  transform: rotate(-45deg) translate(4px, -4.5px);
}

