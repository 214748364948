// Flush list items
//
// Remove borders and border-radius to keep list group items edge-to-edge. Most
// useful within other components (e.g., cards).

.list-group-flush {
  .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }

  &:first-child {
    .list-group-item:first-child {
      border-top: 0;
    }
  }

  &:last-child {
    .list-group-item:last-child {
      border-bottom: 0;
    }
  }
}

.list-group-selector {
  .list-group-item {
    margin-bottom: 0;
    border-bottom-color: transparent;
    padding: 0;

    > .checkbox,
    > .radio {
      margin: 0;
      max-width: none;

      input[type="radio"] {
        margin-left: -25px;
      }
      > label {
        display: block;
        padding-left: 2.5rem;
        padding-right: 1.5rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        label {
          padding-left: 0;
          margin-bottom: 0.25rem;
        }
        .checkbox label,
        .radio label {
          padding-left: 20px;
        }
      }
    }

    .list-group-hidden-content {
      display: none;
    }

    a,
    button,
    .btn {
      position: relative;
      z-index: 3;
    }
    > .close {
      position: absolute;
      top: 0.75rem;
      right: 1.5rem;
      z-index: 3;
    }
    &:last-of-type {
      border-bottom-color: @list-group-border;
    }
    &:hover > .radio > label,
    &:focus > .radio > label {
      background-color: desaturate(@body-bg + 8%, 75%);
    }
    &:last-of-type {
      > .radio > label {
        border-bottom-left-radius: @list-group-border-radius;
        border-bottom-right-radius: @list-group-border-radius;
      }
    }
    &:first-of-type {
      > .radio > label {
        border-top-left-radius: @list-group-border-radius;
        border-top-right-radius: @list-group-border-radius;
      }
    }
    &.list-group-item-selected {
      .list-group-hidden-content {
        display: block;
      }
    }
    &.list-group-item-selected > .radio > label,
    &.list-group-item-selected > .radio > label:hover,
    &.list-group-item-selected > .radio > label:focus {
      border-color: transparent;
      box-shadow: 0 0 0 2px @list-group-selected-border;
      border-radius: @list-group-border-radius;
      position: relative;
      z-index: 1;
      background-color: transparent;
    }
  }
}
