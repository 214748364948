/*
https://github.com/filamentgroup/select-css
http://filamentgroup.github.io/select-css/demo/
https://raw.githubusercontent.com/filamentgroup/select-css/master/src/select.css

/* Container used for styling the custom select, the buttom class below adds the bg gradient, corners, etc. */
.custom-select {
  position: relative;
  display:block;
  padding:0;
}

/* This is the native select, we're making everything but the text invisible so we can see the button styles in the wrapper */
.custom-select select {
  width:100%;
  max-width:none;
  margin:0;
  background:none;
  border: 0;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* Remove select styling */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Custom arrow sits on top of the select - could be an image, SVG, icon font, etc. or the arrow could just baked into the bg image on the select. Note this si a 2x image so it will look bad in browsers that don't support background-size. In production, you'd handle this resolution switch via media query but this is a demo. */
.custom-select::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  right: 1em;
  margin-top:-3px;
  border-top:   4px solid;
  border-right: 4px solid transparent;
  border-left:  4px solid transparent;

  /* This hack make the select behind the arrow clickable in some browsers */
  pointer-events:none;
}

 /* IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance, IE 9 and earlier get a native select - targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5 - looking for better ways to achieve this targeting */
/* The second rule removes the odd blue bg color behind the text in the select button in IE 10/11 and sets the text color to match the focus style's - fix via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .custom-select select::-ms-expand {
    display: none;
  }
  .custom-select select:focus::-ms-value {
    background: transparent;
    color: #222;
  }
}

/* Firefox focus has odd artifacts around the text, this kills that. See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-focusring */
.custom-select select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
