@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  z-index:1;
  display: block;
  height: 100%;
  width: 44px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 0;
  bottom:0;
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    color: transparent; }

  .slick-prev.slick-disabled,
  .slick-next.slick-disabled,
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0; }

.slick-prev:before, .slick-next:before {
  font-family: "icons";
  font-size: 25px;
  line-height: 1;
  color: rgba(125,125,125,.8);
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -1rem; }
  .slick-prev:before {
    content: "\e004"; }

.slick-next {
  right: -1rem; }
  .slick-next:before {
    content: "\e007"; }

/* Dots */
.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: 0;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin-bottom:10px;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 3px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        border-radius:100%;
        width: 10px;
        height: 10px;
        text-align: center;
        background-color:#fff;
        text-shadow:0 0 3px #000;
        opacity: 0.45;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: #fff;
      opacity: 1; }
